<template>
  <div>
    <div class="modal" role="dialog" id="model-add-patient" ref="ModalAddPatient">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Thêm mới bệnh nhân") }}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="flex flex-col gap-1">
              <label>Thêm bệnh nhân vào khoa</label>
              <el-select v-model="listClinicSelected" multiple placeholder="Chọn khoa">
                <el-option v-for="item in listClinics" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="flex flex-col gap-1 mt-3">
              <label>Danh sách bệnh nhân đã chọn ({{ listPatientSelected.length }})</label>
              <el-table ref="multipleTable" :data="listPatient" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="Thông tin bệnh nhân" width="220">
                  <template slot-scope="scope">{{ scope.row.person && scope.row.person.name }}</template>
                </el-table-column>
                <el-table-column property="person.phone" label="Số điện thoại" width="120"></el-table-column>
                <el-table-column property="person.address" label="Địa chỉ" show-overflow-tooltip></el-table-column>
              </el-table>
            </div>
          </div>
          <div class="modal-footer text-right">
            <div class="flex items-center w-100">
              <!-- <el-button type="info" class="flex-1">Huỷ</el-button> -->
              <el-button type="primary" class="flex-1" @click="handleAddPatient" :loading="isLoading">Thêm bệnh nhân</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import vSelect from 'vue-select'
export default {
  name: 'ModalAddPatient',
  props: ['listPatient', 'isShow'],
  components: { vSelect },
  watch: {
    'listPatient.length': {
      handler (value) {
        this.$refs.multipleTable.toggleAllSelection()
        this.listPatientSelected = this.listPatient
      }
    },
    isShow (value) {
      this.listClinicSelected = []
      if (value === true) this.getListWorkspace()
    }
  },
  data () {
    return {
      listClinicSelected: [],
      listClinics: [],
      listPatientSelected: [],
      isLoading: false
    }
  },
  computed: {},
  mounted () {
    //
  },
  methods: {
    handleSelectionChange (value) {
      this.listPatientSelected = value
    },
    async handleAddPatient () {
      if (this.listPatientSelected.length === 0) {
        this.$message.error('Bạn cần chọn bệnh nhân')
      } else if (this.listClinicSelected.length === 0) {
        this.$message.error('Bạn cần chọn khoa')
      } else {
        await this.addPatient(this.listPatientSelected, this.listClinicSelected)
        this.$emit('addPatient')
      }
    },
    async addPatient (listPatient, listClinic) {
      try {
        this.isLoading = true
        await this.$rf
          .getRequest('DoctorRequest')
          .addPatientToHSSK({
            clinic_ids: listClinic,
            person_ids: listPatient?.map(elm => elm.person_id)
          })
          .then(res => {
            if (res.status === 200) {
              this.$message.success('Thêm bệnh nhân thành công!')
            }
          })
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.$message.error('Có lỗi xảy ra, vui lòng thử lại')
        console.log(error)
      }
    },
    async getListWorkspace () {
      let self = this
      try {
        let resp = await self.$rf.getRequest('DoctorRequest').getClinic()
        this.listClinics = resp?.data || []
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        // statements
      }
    }
  }
}
</script>
    <style scoped lang="scss">
input[type="text"] {
  height: 60px;
  color: #20419b;
  font-weight: 500px;
}
.modal-dialog {
  width: 800px;
  max-width: unset;
}
::v-deep(.el-table) {
  .el-table__body-wrapper {
    height: 340px;
    overflow-y: auto;
  }
}
::v-deep(.el-select) {
  .el-select__tags {
    max-height: 150px;
    overflow: auto;
  }
}
</style>
