<template>
  <div class="telemedicine-management contact-list pr-3">
    <div class="card radius-10 pt-2 pl-4 pr-4 border-0">
      <div class="table-responsive">
        <table
          class="table table-vcenter table-mobile-md card-table table-scroll"
        >
          <thead class="d-none d-md-table">
            <tr>
              {{
                $t("obj_health_records.lbl_info_group")
              }}
              <th></th>
              <th class="text-sm-center"></th>
            </tr>
          </thead>
          <tbody
            v-if="groups && groups.data"
            id="contacts"
            :class="groups && groups.count == 10 ? 'h-100' : ''"
          >
            <tr
              v-if="is_loaded"
              class="mt-2 table-tr"
              v-for="(group, index) in groups.data"
              @click="goToDetail(group.id, group.name)"
              style="height: 70px"
            >
              <td data-label="Bệnh sử">
                <span class="robo-20-500 pl-2 text-uppercase">{{
                  group && group.name
                }}</span>
              </td>
              <td class="text-sm-center"></td>
            </tr>
            <tr>
              <td colspan="3" v-if="groups && !groups.data && is_loaded">
                <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
                  <img
                    src="../../../public/assets/images/not-found-search.png"
                    class="img-responsive p-0"
                    alt=""
                  />
                  <p class="robo-16-400 text-black">
                    {{
                      $t(
                        search
                          ? "obj_health_records.lbl_no_question_groups_found"
                          : "obj_health_records.lbl_not_have_any_question_groups_yet"
                      )
                    }}
                  </p>
                </div>
              </td>
            </tr>
            <tr v-for="i in 10" v-if="!is_loaded">
              <td>
                <div class="skeleton-line"></div>
              </td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
          </tbody>
        </table>
        <!-- <Pagination v-if="contacts && contacts.count && is_loaded"  :items="contacts" :current_page="current_page" @onRefresh="onRefresh"></Pagination>
                <div v-if="contacts && !contacts.count && !loading">
                    <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center overflow-hidden">
                        <img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
                        <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy liên hệ mà bạn tìm' : 'Chưa có liên hệ khám nào!' }}</p>
                        <button class="btn-xl btn--blue" type="button" @click="onShowModalAddPerson(true)"><span class="fa fa-plus mr-2"></span> Thêm liên lạc</button>
                    </div>
                </div> -->
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import Pagination from '../../components/Pagination'
export default {
  components: { Pagination },
  name: 'PersonalHealthInformation',
  computed: {},
  data () {
    return {
      groups: null,
      is_loaded: true,
      search: '',
      params: {
        limit: '',
        sort: '',
        sort_by: '',
        search: '',
        page: 1
      },
      type: 'contact-doctor',
      sort: 0,
      loading: false,
      user: null,
      clinic: null,
      list_total_request: null,
      load_end: false,
      current_page: 1,
      person_id: 0
    }
  },
  mounted () {
    let self = this
    self.getGroupQuestion()
    self.person_id = self.$route?.params?.id
  },
  methods: {
    isDoctor () {
      return appUtils.isDoctor()
    },
    isAdmin () {
      return appUtils.isAdmin()
    },
    async getGroupQuestion () {
      let self = this
      try {
        self.groups = {
          data: null,
          count: 0
        }
        var data = []
        let res = await self.$rf
          .getRequest('AuthRequest')
          .getQuestionGroups(self.params)
        var indx = 0
        if (res.data && res.data.data) {
          res.data.data.forEach(async function (element, index) {
            await self.getNameGroupInDetail(element.id).then((result) => {
              indx = indx + 1
              if (result && result.data && result.data.length > 0) {
                data.push(element)
              }
              if (indx === res.data.count) {
                self.groups.count = data.count
                self.groups.data = data
              }
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getNameGroupInDetail (group_id) {
      let self = this
      try {
        var params = {
          sort: '',
          sort_by: '',
          status: 2
        }
        let res = await self.$rf
          .getRequest('AuthRequest')
          .getQuestionByGroups(group_id, params)
        return res && res.data
      } catch (e) {
        console.log(e)
      }
    },
    gotoPersonDetail () {
      let self = this
      self.$router.push({ path: '' })
    },
    formatPhoneNumber (number) {
      let cleaned = ('' + number).replace(/\D/g, '')
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        let hiden_number = ''
        for (var i = 0; i < match[2].length; i++) {
          hiden_number += 'X'
        }
        return match[1] + '-' + hiden_number + '-' + match[3]
      }
      return null
    },
    goToDetail (id, name) {
      // console.log(contact)
      let self = this
      if (self.isDoctor()) {
        self.$router
          .push({
            path: `/doctor/diseases/${self.$route?.params?.id}/health-information-detail?person=${self.$route.query.person}&&type=${id}`
          })
          .catch(() => {})
      } else if (self.isAdmin()) {
        self.$router
          .push({
            path: `/admin/diseases/${self.$route?.params?.id}/health-information-detail?person=${self.$route.query.person}&&type=${id}`
          })
          .catch(() => {})
      }
    },
    getImageURL (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
    },
    formatDateDDMMYYY (time) {
      return window.moment(time).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>
<style scoped>
</style>