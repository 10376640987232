<template>
    <div>
        <div class="modal" tabindex="-1" role="dialog" id="modal--medicine">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-pri">
                        <h5 class="modal-title">Thông tin thuốc</h5>
                        <span class="btn--close"></span>
                    </div>
                    <div class="modal-body">
                        <div class="row mt-2">
                            <div class="col-sm-12">
                                <div style="padding: 15px 15px;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label class="robo-16-500 txt-pri required">Tên thuốc</label>
                                                <input type="text" class="form-control mt-2 txt-pri robo-16-500" placeholder="Nhập tên thuốc" min="0" v-model="name">
                                                <small class="text-red">{{err_name}}</small>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label class="robo-16-500 txt-pri required">Đơn vị thuốc</label>
                                                <input type="text" class="form-control mt-2 txt-pri robo-16-500" placeholder="" v-model="unit">
                                                <small class="text-red">{{err_unit}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-muted" data-dismiss="modal">Huỷ</button>
                        <button type="button" class="btn bg-pri bd-pri text-white" @click="onSubmit()">Cập nhật</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'ModalMedicine',
  props: ['medicine'],
  data () {
    return {
      name: '',
      err_name: '',
      err_unit: '',
      unit: ''
    }
  },
  watch: {
    medicine: {
      handler: function () {
        let self = this
        if (self.medicine) {
          self.name = self.medicine.name
          self.unit = self.medicine.amount_unit
        } else {
          self.name = ''
          self.unit = ''
        }
      },
      deep: true
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modal--medicine').on('hide.bs.modal', function (e) {
        self.name = ''
        self.unit = ''
        self.err_unit = ''
        self.err_name = ''
      })
    })
  },
  methods: {
    onSubmit () {
      let self = this
      if (!self.name) {
        self.err_name = 'Vui lòng nhập tên thuốc'
        return false
      } else if (!self.unit) {
        self.err_unit = 'Vui lòng nhập đơn vị thuốc'
        return false
      } else {
        self.onAddMedicine()
      }
    },
    async onAddMedicine () {
      let self = this
      try {
        // statements
        var params = {
          name: self.name,
          amount_unit: self.unit
        }
        if (appUtils.isAdmin()) {
          if (self.medicine) {
            params.id = self.medicine.id
            self.$emit('onSubmitUpdate', params)
          } else {
            self.$emit('onSubmit', params)
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style scoped></style>