<template>
  <div>
    <div class="modal" tabindex="-1" role="dialog" id="modal-parameter-setting">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header bg-pri">
            <h5
              class="modal-title"
              align="left"
            >{{ $t("obj_health_records.lbl_parameter_settings") }}</h5>
            <button
              type="button"
              class="close mr-2 bg-pri bd-pri text-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3 row">
              <div class="col-4 d-flex align-items-center">
                <label
                  class="ml-3 txt-pri robo-20-500"
                >{{ $t("obj_health_records.lbl_use_example") }}</label>
              </div>
              <div class="col-8">
                <v-select
                  v-if="templates && templates.data"
                  class="mt-2"
                  :options="templates.data"
                  label="diseases_name"
                  :placeholder="$t('obj_health_records.lbl_custom')"
                  :filterable="false"
                  @open="onOpenTemp"
                  @close="onCloseTemp"
                  v-model="template"
                  transition
                  @search="searchTemp"
                >
                  <div slot="no-options">{{ $t("obj_health_records.lbl_not_example") }}</div>
                  <template #list-footer>
                    <li ref="load" v-show="tempHasNextPage"></li>
                  </template>
                </v-select>
              </div>
            </div>
            <SettingStats
              :template="template"
              :current_symptoms="curr_symptoms"
              :current_stats="survival_stats"
              @changeSStats="changeSStats"
              @changeCurrSymp="changeCurrSymp"
              @changeSymp="changeSymp"
            />
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              :disabled="loading"
              type="button"
              class="btn-l radius-0 bg-pri bd-pri text-white px-5"
              @click="onSubmit()"
            >
              <span class="px-5">{{ $t("obj_health_records.lbl_save") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import SettingStats from './SettingStats.vue'
import i18n from '../../lang'
const follow_cycle_units = [
  { id: 2, text: i18n.t('lbl_day') },
  { id: 1, text: i18n.t('lbl_hours') },
  { id: 3, text: i18n.t('lbl_week') },
  { id: 4, text: i18n.t('lbl_month') }
]
export default {
  name: 'ModalParameterSetting',
  props: ['person_symptom', 'person_id', 'person_diseases_id'],
  components: { SettingStats, vSelect },
  data () {
    return {
      loading: false,
      follow_cycle_units,
      survival_stats: [],
      symptoms: [],
      curr_symptoms: [],
      observer: null,
      templates: [],
      template: null,
      searchTempString: ''
    }
  },
  watch: {
    template (_new, old) {
      if (old && !_new) {
        let self = this
        self.survival_stats = []
        self.symptoms = []
        self.curr_symptoms = []
        self.getStats()
        self.getPersonSymptoms()
      }
    }
  },
  mounted () {
    let self = this
    self.getStats()
    self.getPersonSymptoms()
    self.observer = new IntersectionObserver(self.infiniteScroll)
    self.getTemplates(20)
  },
  methods: {
    changeSymp (data) {
      console.log(data)
      this.symptoms = data
    },
    changeCurrSymp (data) {
      this.curr_symptoms = data
    },
    changeSStats (data) {
      this.survival_stats = data
    },
    async getStats () {
      let self = this
      let params = {}
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.person_diseases_id, params)
        .then(resp => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              self.survival_stats.push({
                is_monitor: stat.is_monitor,
                min_threshold: stat.min_threshold,
                max_threshold: stat.max_threshold,
                cycle: stat.cycle,
                cycle_unit: stat.cycle_unit,
                code: stat.code,
                name: stat.name || '',
                unit: stat.unit
              })
            })
          }
        })
    },
    async getPersonSymptoms () {
      let self = this
      let params = {
        // is_doctor: 1
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonSymptoms(self.person_diseases_id, params)
        .then(resp => {
          if (resp && resp.data && resp.data.count) {
            self.curr_symptoms = resp.data.data
          }
        })
    },
    async onSubmit () {
      let self = this
      if (self.loading) return
      if (!self.validateStats() && !self.validateSymptoms()) {
        await self.onSave().then(() => {
          self.$emit('onRefresh')
          self.survival_stats = []
          self.symptoms = []
          self.curr_symptoms = []
          self.getStats()
          self.getPersonSymptoms()
          window.$('#modal-parameter-setting').modal('hide')
        })
      } else {
        alert(this.$t('obj_health_records.lbl_enter_required_info'))
      }
    },
    async onSave () {
      let self = this
      try {
        self.loading = true
        await self.onSaveStats()
        await self.onSaveSymptoms()
      } catch (error) {
      } finally {
        self.loading = false
      }
    },
    async onSaveSymptoms () {
      let self = this
      // add
      if (!self.curr_symptoms.length && !self.symptoms.length) return
      let new_symptoms = []
      self.curr_symptoms.map(symp => {
        if (symp.is_monitor) {
          symp.is_monitor = 1
        } else {
          symp.is_monitor = 0
        }
        new_symptoms.push(symp)
      })
      self.symptoms.map(symp => {
        if (symp.is_monitor) {
          symp.is_monitor = 1
        } else {
          symp.is_monitor = 0
        }
        symp.person_diseases_id = self.person_diseases_id
        symp.person_id = self.person_id
        delete symp.id
        new_symptoms.push(symp)
      })
      new_symptoms = new_symptoms.filter(
        item => item.symptom_name !== '' && item.symptom_name !== null
      )
      if (new_symptoms.length > 0) {
        let params = { symptoms: new_symptoms }
        await self.$rf.getRequest('DoctorRequest').postSymtomps(params)
      }
    },
    async onSaveStats () {
      let self = this
      let person_diseases_id = self.person_diseases_id
      let new_stats = []
      self.survival_stats.map(stat => {
        let new_stat = {
          code: stat.code,
          person_diseases_id: person_diseases_id,
          min_threshold: parseFloat(stat.min_threshold),
          max_threshold: parseFloat(stat.max_threshold),
          cycle: stat.cycle,
          cycle_unit: stat.cycle_unit,
          unit: stat.unit,
          is_monitor: stat.is_monitor ? 1 : 0
        }
        new_stats.push(new_stat)
      })
      await self.$rf
        .getRequest('DoctorRequest')
        .multiSurvivalStatSetting(person_diseases_id, { params: new_stats })
    },
    validateStats () {
      let self = this
      return self.survival_stats.filter(
        stats =>
          stats.is_monitor &&
          stats.stat &&
          (stats.min_threshold === '' ||
            stats.max_threshold === '' ||
            isNaN(stats.min_threshold) ||
            isNaN(stats.max_threshold) ||
            parseFloat(stats.min_threshold) > parseFloat(stats.max_threshold) ||
            parseFloat(stats.min_threshold) < 0 ||
            parseFloat(stats.max_threshold) < 0 ||
            stats.cycle === '' ||
            isNaN(stats.cycle))
      ).length
    },
    validateSymptoms () {
      return this.symptoms.filter(symp => !symp.symptom_name).length
    },
    tempHasNextPage () {
      let self = this
      if (self.templates.total > self.templates.count) {
        return true
      }
      return false
    },
    async onOpenTemp () {
      if (this.tempHasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onCloseTemp () {
      this.observer.disconnect()
      self.searchTempString = ''
    },
    searchTemp (search) {
      let self = this
      self.searchTempString = search
      self.getTemplates(20)
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var params = {
          limit: this.templates.count + 20
        }
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.getTemplates(params.limit)
      }
    },
    async getTemplates (limit) {
      let self = this
      if (!this.$clinicId) return
      let params = {
        limit
      }
      if (self.searchTempString) {
        params.search = self.searchTempString
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getTemplates(this.$clinicId, params)
        .then(res => {
          self.templates = res.data
        })
    }
  }
}
</script>
<style scoped="true">
.form-control {
  color: #20419b !important;
  font-family: 'roboto';
  font-size: 16px;
  font-weight: 500;
}
</style>