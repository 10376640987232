<template>
  <div>
    <div class="table-responsive">
      <table class="table table-vcenter table-mobile-md card-table table-scroll">
        <thead class="d-none d-md-table bg--primary-corl">
          <tr class="">
            <th
              class="robo-20-500"
              style="background-color: #E7EAF5 !important;"
            ><span class="txt-pri-corl">Tên thuốc</span></th>
            <th
              class="robo-20-500"
              style="background-color: #E7EAF5 !important;"
            ><span class="txt-pri-corl">ĐVT</span></th>
            <th
              class="robo-20-500"
              style="background-color: #E7EAF5 !important;"
            ><span class="txt-pri-corl">SL</span></th>
            <th
              class="robo-20-500"
              style="background-color: #E7EAF5 !important;"
            ><span class="txt-pri-corl">Lần / ngày</span></th>
            <th
              class="robo-20-500"
              style="background-color: #E7EAF5 !important;"
            ><span class="txt-pri-corl">Liều lượng / ngày</span></th>
            <th
              class="robo-20-500"
              style="background-color: #E7EAF5 !important;"
            ><span class="txt-pri-corl">Cách ngày</span></th>
            <th
              class="robo-20-500"
              style="background-color: #E7EAF5 !important;"
            ><span class="txt-pri-corl">Bắt đầu</span></th>
            <th
              class="robo-20-500"
              style="background-color: #E7EAF5 !important;"
            >
              <div align="right">
                <span class="mr-2 txt-pri-corl">Theo dõi</span>
                <!-- <input type="checkbox" class="form-check-input"> -->
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          id="invites"
          class="bg-white"
        >
          <tr style="min-height: 77px;">
            <td
              colspan="7"
              class="text-center robo-20-500"
            >
              <span class="txt-pri-corl">Thông tin thuốc</span>
            </td>
          </tr>
          <tr
            class="border-bottom"
            style="min-height: 77px;"
            v-for="(medicine, index) in form"
            :key="index"
          >
            <td colspan="7">
              <MedicineAdd
                :medicines="medicines"
                :prescription="medicine"
              ></MedicineAdd><br>
            </td>
          </tr>
          <tr style="min-height: 77px;">
            <td
              colspan="7"
              class="text-center"
            >
              <div
                class="btn border-0 txt-pri-corl robo-20-500"
                @click="onMedicineAdd()"
              >
                <img src="../../../public/assets/images/icon/icon-add-bule.svg"> Thêm thuốc
              </div>
            </td>
          </tr>
          <tr class="mt-5">
            <td
              colspan="7"
              class="text-center"
            >
              <div align="center">
                <button
                  class="btn mr-2 border-0"
                  style="width: 164px;"
                  @click="onCanelModel()"
                >Huỷ bỏ</button>
                <button
                  class="btn border-0 bg--primary--corl text-white btn-icon-add-sm"
                  style="width: 164px; background-color: #20419B;"
                  @click="onSubmit()"
                >Lưu</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import MedicineAdd from './MedicineAdd'
export default {
  name: 'MedicineInfo',
  components: { MedicineAdd },
  data () {
    return {
      form: [{
        id: '',
        person_id: '',
        name: '',
        person_diseases_id: '',
        amount_unit: '',
        doctor_note: '',
        person_note: '',
        freq_per_day: 1,
        amount_per_one: 1,
        gap_day: '',
        is_monitor: 1,
        amount: 1,
        side_effect: '',
        error: false
      }],
      medicines: [],
      person_diseases_id: '',
      person_id: ''
    }
  },
  mounted () {
    let self = this
    self.getMedicine()
    self.person_diseases_id = self.$route.params ? self.$route?.params?.id : ''
    self.form[0].person_diseases_id = self.$route?.params?.id
    self.person_id = self.$route.query ? self.$route.query.person : ''
    self.form[0].person_id = self.person_id
  },
  methods: {
    fontmatDate (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    onMedicineAdd () {
      let self = this
      let param = {
        id: '',
        person_id: self.person_id,
        name: '',
        person_diseases_id: self.$route?.params?.id,
        amount_unit: '',
        doctor_note: '',
        person_note: '',
        freq_per_day: 1,
        amount_per_one: 1,
        gap_day: '',
        is_monitor: 1,
        amount: 1,
        side_effect: '',
        error: false
      }
      self.form.push(param)
    },
    async getMedicine () {
      let self = this
      try {
        await self.$rf.getRequest('AuthRequest').getMedicines().then(res => {
          if (res && res.data) {
            let num = res.data.total + 1
            self.getMedicineNumber(num)
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async getMedicineNumber (num) {
      let self = this
      try {
        var param = {
          limit: num
        }
        await self.$rf.getRequest('AuthRequest').getMedicines(param).then(res => {
          if (res && res.data) {
            self.medicines = res.data
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    onCheckValidate () {
      let self = this
      if (self.form) {
        self.form.forEach(function (ele) {
          if (!ele.name) {
            ele.error = true
          } else if (!ele.name) {
            ele.error = true
          } else if (!ele.amount_unit) {
            ele.error = true
          } else if (!ele.freq_per_day) {
            ele.error = true
          } else if (!ele.amount_per_one) {
            ele.error = true
          } else {
            ele.error = false
          }
        })
        var arr = self.form.filter(item => item.error === true)
        if (arr && arr[0]) {
          return false
        } else {
          return true
        }
      }
    },
    async onSubmit () {
      let self = this
      try {
        if (self.form && self.$route?.params?.id) {
          var param = {
            person_diseases_id: self.$route?.params?.id,
            person_medicines: self.form
          }
          if (self.onCheckValidate()) {
            if (appUtils.isDoctor()) {
              await self.$rf.getRequest('DoctorRequest').postPersonMedicines(param).then(res => {
                if (res) {
                  self.$toast.open({
                    message: 'Thêm thành công',
                    type: 'success'
                  })
                }
                self.onCanelModel()
              })
            } else if (appUtils.isAdmin()) {
              await self.$rf.getRequest('AdminRequest').postPersonMedicines(param).then(res => {
                if (res) {
                  self.$toast.open({
                    message: 'Thêm thành công',
                    type: 'success'
                  })
                }
                self.onCanelModel()
              })
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    // async getPersonsDiseases () {
    //   let self = this
    //   try {
    //     await self.$rf.getRequest('DoctorRequest').getPersonsDiseases(self.person_id).then(res => {
    //       if (res && res.data && res.data.data && res.data.data[0]) {
    //         self.personsDiseases = res.data.data[0]
    //         self.person_diseases_id = res.data.data[res.data.data.length - 1].id
    //       }
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    onCanelModel () {
      let self = this
      if (appUtils.isDoctor()) {
        self.$router.push({ path: `/doctor/diseases/${self.$route?.params?.id}/prescription` }).catch(_ => { })
      } else if (appUtils.isAdmin()) {
        self.$router.push({ path: `/admin/diseases/${self.$route?.params?.id}/medicine?person=${self.$route.query.person}` }).catch(_ => { })
      }
    }
  }
}
</script>
<style scoped>
</style>