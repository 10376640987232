<template>
	<div>
		<div class="row">
			<div class="col-sm-3 mt-2">
				<div class="form-group">					
					<label for="txt-name" class="required text-black-50 robo-20-500">Tên thuốc / liều lượng</label>
					<input type="text" class="form-control robo-16-400 mt-2" v-model="prescription.name" autocomplete="off">
					<small class="text--red" v-if="prescription.error && !prescription.name">Không để tên thuốc trống</small>
				</div>
			</div>
			<div class="col-sm-2 mt-2">
				<div class="form-group">
					<label for="txt-unit" class="required text-black-50 robo-20-500">ĐVT</label>
					<input type="text" class="form-control robo-16-400 mt-2" v-model="prescription.amount_unit" autocomplete="off">
					<small class="text--red" v-if="prescription.error && !prescription.amount_unit">Không để đơn vị trống trống</small>
				</div>
			</div>
			<div class="col-sm-2 mt-2">
				<div class="form-group">
					<label for="txt-num" class="required text-black-50 robo-20-500">SL</label>
					<input type="text" class="form-control mt-2 robo-16-400" id="txt-num" placeholder="10 ..." v-model="prescription.amount" autocomplete="off">
					<small class="text--red" v-if="prescription.error && !prescription.amount">Không để số lượng trống</small>
				</div>
			</div>
			<div class="col-sm-2 mt-2">
				<div class="form-group">
					<label for="txt-per-day" class="required text-black-50 robo-20-500">Lần / ngày</label>
					<div class="d-flex">
						<input type="text" class="form-control mt-2 robo-16-400" id="txt-per-day" placeholder="10 ..." v-model="prescription.freq_per_day" autocomplete="off">
						<span class="input-group-text border-0 mt-2 robo-16-400 txt-pri-corl">Lần</span>
					</div>
					<small class="text--red" v-if="prescription.error && !prescription.freq_per_day">Không để lần / ngày trống</small>
				</div>
			</div>
			<div class="col-sm-3 mt-2">
				<div class="form-group">
					<label for="txt-lieuluong" class="required text-black-50 robo-20-500">Liều lượng mỗi lần sử dụng</label>
					<input type="text" class="form-control mt-2 robo-16-400" id="txt-lieuluong" placeholder="10 ..." v-model="prescription.amount_per_one" autocomplete="off">
				</div>
				<small class="text--red" v-if="prescription.error && !prescription.amount_per_one">Không để liều lượng mỗi lần sử dụng trống</small>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-6 mt-2">
				<div class="form-group">
					<label for="txt-introduce" class="text-black-50 robo-20-500">Hướng dẫn uống thuốc</label>
					<input type="email" class="form-control robo-16-400 mt-2" id="txt-introduce" placeholder="Uống sau ăn" v-model="prescription.doctor_note" autocomplete="off">
				</div>
			</div>
			<div class="col-sm-6 mt-2">
				<div class="form-group">
					<label for="txt-drink" class="text-black-50 robo-20-500">Uống cách ngày</label>
					<div class="d-flex">
						<input type="text" class="form-control mt-2 robo-16-400" id="txt-drink" placeholder="" v-model="prescription.gap_day" autocomplete="off">
						<span class="input-group-text border-0 mt-2 robo-16-400 txt-pri-corl">Ngày</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-6 mt-2">
				<div class="form-group">
					<label for="txt-introduce" class="text-black-50 robo-20-500">Tác dụng phụ</label>
					<input type="text" class="form-control mt-2 robo-16-400" id="txt-day" placeholder="" v-model="prescription.side_effect" autocomplete="off">
				</div>
			</div>
			<div class="col-sm-6 mt-5">
				<div class="form-check mb-2 mr-sm-2">
					<input class="form-check-input" type="checkbox" id="inlineFormCheck" v-model="prescription.is_monitor" autocomplete="off" true-value="1" false-value="0">
					<label class="form-check-label" for="inlineFormCheck">
						Theo dõi tuân thủ thuốc
					</label>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'MedicineAdd',
  props: ['medicines', 'prescription'],
  methods: {
  }
}
</script>
<style scoped>
</style>