<template>
  <div>
    <div class="modal" tabindex="-1" role="dialog" id="modal__stats">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-pri">
            <h5 class="modal-title">{{ name }}</h5>
            <span class="btn--close"></span>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div style="padding: 15px 15px">
                  <div class="row">
                    <div
                      v-for="st in survival_stats"
                      class="col-lg-3 col-md-6 mb-3"
                      :key="st.id"
                    >
                      <div class="form-group">
                        <label class="robo-16-500 txt-pri">{{ st.name }}</label>
                        <div v-if="st.code === 'huyet_ap'">
                          <div class="form-group d-flex align-items-center">
                            <input
                              type="number"
                              step="any"
                              class="form-control mt-2 txt-pri robo-16-500 text-right"
                              :placeholder="
                                $t('obj_health_records.lbl_enter_data')
                              "
                              min="0"
                              v-model="st.value"
                            />
                            <span class="robo-32-400 mx-1"> / </span>
                            <input
                              type="number"
                              step="any"
                              class="form-control mt-2 txt-pri robo-16-500"
                              :placeholder="
                                $t('obj_health_records.lbl_enter_data')
                              "
                              min="0"
                              v-model="st.value2"
                            />
                          </div>
                          <small class="text-red">{{
                            getErr(st.value, st.value2, true)
                          }}</small>
                        </div>
                        <div v-if="st.code !== 'huyet_ap'">
                          <input
                            type="number"
                            step="any"
                            class="form-control mt-2 txt-pri robo-16-500"
                            :placeholder="
                              $t('obj_health_records.lbl_enter_data')
                            "
                            min="0"
                            v-model="st.value"
                          />
                          <small class="text-red">{{ getErr(st.value) }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label class="robo-16-500 txt-pri">{{
                          $t("obj_health_records.lbl_date")
                        }}</label>
                        <div>
                          <div class="form-group">
                            <input
                              type="date"
                              class="form-control mt-2 txt-pri robo-16-500 text-right"
                              placeholder=""
                              v-model="created_at"
                            />
                          </div>
                          <small class="text-red">{{ err_create_at }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-muted" data-dismiss="modal">
              {{ $t("obj_health_records.lbl_cancel") }}
            </button>
            <button
              type="button"
              class="btn bg-pri bd-pri text-white"
              @click="onSubmit()"
            >
              {{ $t("obj_health_records.lbl_update") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['monitor_stats', 'name'],
  name: 'ModalSurvivalStatsValues',

  data () {
    return {
      survival_stats: [
        {
          name: this.$t('obj_health_records.lbl_pulse'),
          code: 'tim_mach',
          unit: this.$t('obj_health_records.lbl_beats_minute'),
          value: '',
          error: ''
        }
      ],
      created_at: null,
      err_create_at: ''
    }
  },
  watch: {
    monitor_stats (stats) {
      if (stats) {
        this.survival_stats = []
        stats.forEach((s) => {
          if (s.code === 'huyet_ap') {
            this.survival_stats.push({
              name: s.name,
              code: s.code,
              unit: s.unit,
              value: '',
              value2: '',
              error: ''
            })
          } else {
            this.survival_stats.push({
              name: s.name,
              code: s.code,
              unit: s.unit,
              value: '',
              error: ''
            })
          }
        })
      }
    }
  },
  mounted () {
    let self = this
    self.created_at = window.moment().format('YYYY-MM-DD')
  },
  methods: {
    onSubmit () {
      let self = this
      if (!self.validate() && self.getErrDate(self.created_at)) {
        let params = []

        self.survival_stats.forEach((st) => {
          let value_string = ''
          if (st.code === 'huyet_ap') {
            if (st.value !== '' && st.value2 !== '') {
              let string = st.value + '/' + st.value2
              value_string = string
            }
          } else {
            if (st.value !== '') {
              value_string = st.value
            }
          }
          if (value_string) {
            params.push({
              code: st.code,
              value: value_string,
              created_at: self.isToday(self.created_at)
                ? self.formatHMDMY()
                : self.formatHMDMY(self.created_at)
            })
          }
        })
        self.$emit('onSubmit', params)
      }
    },
    validate () {
      let is_invalid = false
      this.survival_stats.map((st) => {
        if (st.code === 'huyet_ap') {
          if (
            (st.value !== '' || st.value2 !== '') &&
            (st.value === '' || st.value2 === '')
          ) {
            is_invalid = true
          } else {
            if (st.value !== '' && st.value2 !== '') {
              if (
                isNaN(st.value) ||
                st.value < 0 ||
                isNaN(st.value2) ||
                st.value2 < 0
              ) {
                is_invalid = true
              }
            }
          }
        } else {
          if (isNaN(st.value) || st.value < 0) {
            is_invalid = true
          }
        }
      })
      return is_invalid
    },
    getErr (vl1, vl2, is_ha) {
      if (is_ha) {
        if ((vl1 !== '' || vl2 !== '') && (vl1 === '' || vl2 === '')) {
          return this.$t('obj_health_records.lbl_enter_full_data')
        } else {
          if (vl1 !== '' && vl2 !== '') {
            if (isNaN(vl1) || vl1 < 0 || isNaN(vl2) || vl2 < 0) {
              return this.$t('obj_health_records.lbl_value_greater_than_zero')
            }
          }
        }
      } else {
        if (isNaN(vl1) || vl1 < 0) {
          return this.$t('obj_health_records.lbl_value_greater_than_zero')
        }
      }
    },
    getErrDate (date_time) {
      let self = this
      if (date_time) {
        self.err_create_at = ''
        return true
      } else {
        if (!self.validate()) {
          self.err_create_at = this.$t(
            'obj_health_records.lbl_please_enter_the_creation_date'
          )
          return false
        } else {
          self.err_create_at = ''
          return false
        }
      }
    },
    formatHMDMY (date_time) {
      return window.moment(date_time).format('HH:mm DD-MM-YYYY')
    },
    isToday (date) {
      return window
        .moment(date)
        .isSame(window.moment().format('YYYY-MM-DD'), 'day')
    }
  }
}
</script>
<style lang="css" scoped>
</style>