<template>
  <div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="modal_result_create"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-pri">
            <h5 class="modal-title">
              {{ $t("obj_health_records.lbl_diagnose") }}
            </h5>
            <span class="btn--close"></span>
          </div>
          <div class="modal-body py-3 px-4">
            <div class="item-wrapper">
              <div class="left-content" align="center"></div>
              <div class="right-content">
                <div class="row mb-3 border-bottom pb-3">
                  <div class="col-lg-6">
                    <label
                      for="test_name"
                      class="required robo-16-500 txt-grey-900 mb-1"
                    >
                      {{ $t("obj_health_records.lbl_group_name") }}
                    </label>
                    <input
                      type="text"
                      class="form-control bg-blue-gray"
                      v-model="result_name"
                      :class="!result_name ? 'is-invalid' : ''"
                    />
                    <div v-if="!result_name" class="invalid-feedback">
                      {{ $t("obj_health_records.lbl_please_enter_group_name") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="item in result_items"
              class="item-wrapper mb-3 border-bottom"
              :set="(validate = validateData(item))"
            >
              <div
                class="left-content d-flex justify-content-center align-items-start"
                align="center"
              >
                <img
                  src="../../../public/assets/images/icon/trash.svg"
                  width="24"
                  height="24"
                  class="cursor-pointer"
                  alt=""
                  @click="onDelete(idx)"
                />
              </div>
              <div class="right-content">
                <div class="row mb-3 mx-0">
                  <label
                    for="exploration_name"
                    class="required robo-16-500 txt-grey-600 mb-1"
                  >
                    {{ $t("obj_health_records.lbl_diagnose") }}
                  </label>
                  <input
                    type="text"
                    class="form-control bg-blue-gray"
                    :class="
                      !!validate.exploration_name &&
                      validate.exploration_name !== ''
                        ? 'is-invalid'
                        : ''
                    "
                    v-model="item.exploration_name"
                  />
                  <div
                    v-if="validate.exploration_name"
                    class="invalid-feedback"
                  >
                    {{ validate.exploration_name }}
                  </div>
                </div>
                <div class="row mb-3 mx-0">
                  <label
                    for="exploration_result"
                    class="required robo-16-500 txt-grey-600 mb-1"
                  >
                    {{ $t("obj_health_records.lbl_conclude") }}
                  </label>
                  <textarea
                    type="text"
                    class="form-control bg-blue-gray"
                    :class="
                      !!validate.exploration_result &&
                      validate.exploration_result !== ''
                        ? 'is-invalid'
                        : ''
                    "
                    v-model="item.exploration_result"
                  />
                  <div
                    v-if="validate.exploration_result"
                    class="invalid-feedback"
                  >
                    {{ validate.exploration_result }}
                  </div>
                </div>
                <div class="row mb-3 mx-0">
                  <label
                    for="exploration_desc"
                    class="required robo-16-500 txt-grey-600 mb-1"
                  >
                    {{ $t("obj_health_records.lbl_detail") }}
                  </label>
                  <textarea
                    type="text"
                    class="form-control bg-blue-gray"
                    :class="
                      !!validate.exploration_desc &&
                      validate.exploration_desc !== ''
                        ? 'is-invalid'
                        : ''
                    "
                    v-model="item.exploration_desc"
                  />
                  <div
                    v-if="validate.exploration_desc"
                    class="invalid-feedback"
                  >
                    {{ validate.exploration_desc }}
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <a
                href="javascript:;"
                @click="addAResult()"
                class="robo-20-500 txt-pri"
              >
                <img
                  src="../../../public/assets/images/icon/plus-circle.svg"
                  alt=""
                  class="mr-2"
                  width="24"
                  height="24"
                />
                {{ $t("obj_health_records.lbl_add_other_diagnose") }}
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center my-3">
            <button
              :disabled="is_loading"
              type="button"
              class="btn bg-pri bd-pri text-white px-5 radius-0"
              @click="onSubmit()"
            >
              <span class="mx-3">
                {{ $t("obj_health_records.lbl_save") }}
              </span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                v-if="is_loading"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalCreateResult',
  props: ['results', 'loading'],
  data () {
    return {
      is_loading: false,
      result_items: [
        {
          exploration_name: '',
          exploration_result: '',
          exploration_desc: ''
        }
      ],
      result_name: ''
    }
  },
  watch: {
    loading: function (_new, old) {
      this.is_loading = _new
    },
    results: function (_new, old) {
      this.result_items = _new
    }
  },
  mounted () {},
  methods: {
    onSubmit () {
      let self = this
      if (!self.result_name) return
      if (!self.result_items || !self.result_items.length) {
        alert(this.$t('obj_health_records.lbl_not_add_diagnose'))
        return
      }
      let is_invalid = self.result_items.filter(
        (t) => self.validateData(t).is_invalid
      )
      if (is_invalid.length) return
      self.$emit('onSaveResult', self.result_items, self.result_name)
    },
    onDelete (index) {
      this.result_items.splice(index, 1)
    },
    addAResult () {
      let self = this
      self.result_items.push({
        exploration_name: '',
        exploration_result: '',
        exploration_desc: ''
      })
    },
    validateData (result) {
      let params = {
        is_invalid: false,
        exploration_name: '',
        exploration_result: '',
        exploration_desc: ''
      }
      if (!result.exploration_name) {
        params.is_invalid = true
        params.exploration_name = this.$t(
          'obj_health_records.lbl_please_enter_diagnose'
        )
      }

      if (!result.exploration_result) {
        params.is_invalid = true
        params.exploration_result = this.$t(
          'obj_health_records.lbl_please_enter_reslut'
        )
      }

      if (!result.exploration_desc) {
        params.is_invalid = true
        params.exploration_desc = this.$t(
          'obj_health_records.lbl_please_enter_detail'
        )
      }

      return params
    }
  }
}
</script>
<style lang="css" scoped="true">
.item-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left-content {
  width: 32px;
  padding-right: 8px;
}

.right-content {
  width: calc(100% - 40px);
}

.form-control {
  font-size: 16px;
  font-weight: 400;
  font-family: "roboto";
  color: #222222;
  border-radius: 0;
  background-color: #f2f5fa;
}
</style>