<template>
    <div>
        <div class="table-responsive">
            <table class="table table-vcenter table-mobile-md card-table table-scroll">
                <tbody style="height: 200px; overflow-x: auto; overflow-y: hidden;" v-if="isDoctor()">
                    <tr>
                        <td colspan="5">
                            <p class="robo-14-500 txt-pri my-1">Hình ảnh</p>
                            <div class="w-100 d-flex mb-2">
                                <div v-for="(doc,idx) in documents.data" class="mr-2">
                                    <div v-if="isImage(doc.name)">
                                        <img :src="getLinkDownload(doc.link)" alt="" width="200" height="100" @click="index=idx" class="cursor-pointer mr-2 bg-dark img-fit-cover" />
                                        <div class="text-center mt-1 file-name">
                                            <p class="txt-pri mb-0">{{doc.content}}</p>
                                            <p class="txt-pri">{{fontmatDate(doc.created_at)}}</p>
                                        </div>
                                    </div>
                                    <a v-if="!isImage(doc.name)" download target="_blank" :href="getLinkDownload(doc.link)" :download="doc.name">
                                        <div class="cursor-pointer d-flex justify-content-center align-items-center">
                                            <img src="../../../public/assets/images/icon/dowload-file.svg" alt="" width="100" height="100" class="cursor-pointer" />
                                        </div>
                                        <div class="text-center mt-1 file-name">
                                            <p class="txt-pri mb-0">{{doc.content}}</p>
                                            <p class="txt-pri">{{fontmatDate(doc.created_at)}}</p>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <div @click="showUpload" class="upload-btn cursor-pointer">
                                        <img src="../../../public/assets/images/icon/upload-pri.svg" alt="" width="50" height="50" class="cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-vcenter table-mobile-md card-table table-scroll">
                <thead class="d-none d-md-table bg--primary-corl">
                    <tr>
                        <th class="w-md-30 robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri ml-2">Tên thuốc</span></th>
                        <th class=" robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri">ĐVT</span></th>
                        <th class=" robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri">SL</span></th>
                        <th class=" robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri">Lần / ngày</span></th>
                        <th class=" robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri">Liều lượng / lần</span></th>
                        <th class=" robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri">Bắt đầu</span></th>
                        <th class=" robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri">Cách ngày</span></th>
                        <th class=" robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri">Ngày tạo</span></th>
                        <th class=" robo-20-500 text-center" style="background-color: #E7EAF5 !important;">
                            <div>
                                <span class="mr-2 txt-pri">Theo dõi</span>
                                <!-- <input type="checkbox" class="form-check-input" @click="checkAll()"> -->
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="medicines && medicines.data" class="h-100">
                    <!-- <div v-if="medicines && medicines.count > 0" v-for="(medicine, md_idx) in medicines.data" class="border-0"> -->
                    <tr v-if="medicines && medicines.count > 0" v-for="(medicine, md_idx) in medicines.data" class="border-0">
                        <td data-label="Tên thuốc" class="w-md-30 table-td-name-avatar robo-20-400">
                            <span class="txt-pri ml-2">{{medicine && medicine.name}}</span>
                            <span class="txt-pri">{{medicine && medicine.medicine && medicine.medicine.name}}</span>
                            <div class="robo-14-400 txt-pri"><span class="ml-3">Tác dụng phụ: {{medicine && medicine.side_effect}}</span></div>
                            <div class="robo-14-400 txt-pri"><span class="ml-3">Hướng dẫn: {{medicine && medicine.doctor_note}}</span></div>
                        </td>
                        <td data-label="ĐVT" style="text-transform: capitalize;" class="robo-20-400">
                            <span class="txt-pri">{{medicine && medicine.medicine && medicine.medicine.amount_unit}}</span>
                            <span class="txt-pri">{{medicine && medicine.amount_unit}}</span>
                        </td>
                        <td data-label="SL">
                            <span class="txt-pri">{{medicine && medicine.amount}}</span>
                        </td>
                        <td data-label="Lần/ngày">
                            <span class="txt-pri">{{medicine && medicine.freq_per_day}} lần</span>
                        </td>
                        <td data-label="Liều lượng / lần">
                            <span class="txt-pri">{{medicine && medicine.amount_per_one}} {{medicine && medicine.medicine && medicine.medicine.amount_unit}}</span>
                        </td>
                        <td data-label="Bắt đầu">
                            <span class="txt-pri">{{medicine && medicine.status ? 'Đã bắt đầu' : 'Mới'}}</span>
                        </td>
                        <td data-label="Cách ngày">
                            <span class="txt-pri">{{medicine && medicine.gap_day}} ngày</span>
                        </td>
                        <td data-label="Ngày tạo">
                            <span class="txt-pri">{{medicine && medicine.created_at ? fontmatDate(medicine.created_at) : ''}}</span>
                        </td>
                        <td data-label="Theo dõi" class="text-md-center robo-20-400">
                            <div><input type="checkbox" class="form-check-input" :checked="getTrueOrFalse(medicine && medicine.is_monitor)" @click="changeDrugTracking(medicine && medicine.id)" style="cursor: pointer;"></div>
                        </td>
                    </tr>
                    <!-- <tr class="border-0">
                            <td colspan="12">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="12">
                            </td>
                        </tr> -->
                    <!-- </div> -->
                    <tr v-if="(!medicines || !medicines.count) && (!documents || !documents.count)">
                        <td colspan="5">
                            <div class="col-sm-12 robo-14-400 text-center">Không có dữ liệu</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination v-show="medicines && medicines.count" :items="medicines" :current_page="current_page" @onRefresh="getPersonsMedicines"></Pagination>
        </div>
        <CoolLightBox v-if="documents && documents.count" :items="getImagesFromEnries(documents.data)" :index="index" :useZoomBar="true" @close="index = null">
        </CoolLightBox>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import Pagination from '../../components/Pagination'
export default {
  name: 'MedicineTable',
  props: ['person', 'disease', 'documents'],
  components: { Pagination },
  data () {
    return {
      medicines: null,
      person_id: '',
      medicines_list: null,
      current_page: 1,
      index: null
    }
  },
  mounted () {
    let self = this
    self.person_id = self.person ? self.person.id : ''
    self.getPersonsMedicines()
  },
  methods: {
    fontmatDate (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    isDoctor () {
      return appUtils.isDoctor()
    },
    async getPersonsMedicines () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        if (appUtils.isDoctor()) {
          let param = {
            page: self.current_page,
            person_diseases_id: self.disease && self.disease.id
          }
          await self.$rf.getRequest('DoctorRequest').getPersonsMedicines(self.person_id, param).then(res => {
            if (res && res.data) {
              self.medicines = res.data
            }
          })
        } else if (appUtils.isAdmin()) {
          let param = {
            page: self.current_page,
            person_diseases_id: self.$route?.params?.id
          }
          await self.$rf.getRequest('AdminRequest').getMedicines(param).then(res => {
            if (res && res.data) {
              self.medicines = res.data
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    getTrueOrFalse (type) {
      if (type === 1) {
        return true
      } else {
        return false
      }
    },
    getImagesFromEnries (entries) {
      let results = []
      entries.forEach(file => {
        if (this.isImage(file.name)) {
          let file_name = file.name.split('.')
          results.push({
            title: file.content,
            src: appUtils.getDocumentURL(file.link),
            type: file_name ? file_name.pop().toLowerCase() : ''
          })
        }
      })
      return results
    },
    isImage (file_name) {
      return (file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null)
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    showUpload () {
      this.$emit('showUpload', true)
    },
    async changeDrugTracking (id) {
      let self = this
      try {
        // statements
        if (id) {
          var r = confirm('Bạn có chắc chắn thực hiện thao tác này?')
          if (r) {
            if (appUtils.isDoctor()) {
              await self.$rf.getRequest('DoctorRequest').postPersonMedicinesMonitor(id).then(res => {
                self.getPersonsMedicines()
              })
            } else if (appUtils.isAdmin()) {
              await self.$rf.getRequest('AdminRequest').postPersonMedicinesMonitor(id).then(res => {
                self.getPersonsMedicines()
              })
            }
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style scoped>
.icon-drop {
    background-image: url('../../../public/assets/images/icon/icon-drop.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center right;
}

.icon-next {
    background-image: url('../../../public/assets/images/icon/icon-next.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center right;
}
.upload-btn {
  height: 100px;
  width: 100px;
  border: dashed 2px #20419B;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-name {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
}

.file-name p {
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 765px) {
  .w-lg-30 {
    width: 30% !important;
}
}
</style>