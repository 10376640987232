<template>
  <div class="row">
    <div class="col-lg-6" v-if="person">
      <div class="row">
        <div class="col-6">
          <div
            class="d-flex mb-2"
            v-if="can('view-patient-contact') || isAdmin"
          >
            <img
              src="../../../public/assets/images/icon/Avatar.png"
              class="rounded-circle avatar mr-3"
            />
            <div class="info-container">
              <p class="p-name mb-0 robo-16-500 txt-pri">{{ person.name }}</p>
              <p class="p-info mb-0 robo-14-400 txt-black mt-1">
                {{ getGender(person) }}, {{ person.address }}
              </p>
              <p
                class="p-info mb-0 robo-14-400 txt-black mt-1"
                v-if="person.birthday"
              >
                {{ getAges(person) }}
              </p>
              <p
                class="p-info mb-0 robo-14-400 txt-black mt-1 mb-1"
                v-if="person.phone && type !== 'waiting-profile-details'"
              >
                {{ person.phone }}
              </p>
              <!-- <a href="javascript:;" v-if="!!person && person.user && type !== 'waiting-profile-details'" @click="voiceCall(person.user)">
                                <img src="../../../public/assets/images/icon/btn-voice-call.svg" alt="" class="mr-1 " />
                            </a>
                            <a href="javascript:;" v-if="!!person && person.user && type !== 'waiting-profile-details'" @click="videoCall(person.user)">
                                <img src="../../../public/assets/images/icon/btn-video-call.svg" alt="" class="mr-1 " />
                            </a> -->
            </div>
          </div>
        </div>
        <div class="col-6" v-if="can('view-patient-contact') || isAdmin">
          <div class="info-container" v-if="!!disease">
            <p class="p-name mb-0 robo-16-500 txt-pri">
              {{ disease.contact_name }}
            </p>
            <p class="p-info mb-0 robo-14-400 txt-black mt-1">
              {{ disease.contact_relationship }}
            </p>
            <p
              class="p-info mb-0 robo-14-400 txt-black mt-1 mb-1"
              v-if="person.phone && type !== 'waiting-profile-details'"
            >
              {{ disease.contact_phone }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6" v-if="person">
      <div class="row mb-1">
        <p v-if="disease" class="col-6">
          <span class="robo-16-500 txt-grey-600"
            >{{ $t("obj_health_records.lbl_diagnose") }}:
          </span>
          <span class="robo-16-400 txt-pri">{{
            disease.diseases_name || $t("obj_health_records.lbl_not_info")
          }}</span>
          <!-- <span class="robo-16-400 txt-pri"> {{disease && disease.diseases_stage}}</span> -->
        </p>
        <p v-if="disease && disease.treatment_summary" class="col-6">
          <span class="robo-16-500 txt-grey-600"
            >{{ $t("obj_health_records.lbl_treated") }}: </span
          ><span class="robo-16-400 txt-pri">{{
            disease.treatment_summary || $t("obj_health_records.lbl_not_info")
          }}</span>
        </p>
      </div>
      <div
        class="row my-2"
        v-if="person.stats && type !== 'waiting-profile-details'"
        :set="(filtered_stats = filterStats())"
      >
        <div
          class="col-xxl-4 col-sm-6 d-flex align-items-center"
          v-for="stat in filtered_stats"
          :key="stat.id"
        >
          <p class="robo-16-500 txt-grey-600 mr-1">
            <!-- {{
              stat.name
                ? $t(`obj_health_records.lbl_${stat.code.toLowerCase()}`)
                : `(${this.$t("obj_health_records.lbl_index_code")}: ` +
                  stat.code +
                  ")"
            }}: -->
            {{
              stat.name ||
              `(${this.$t("obj_health_records.lbl_index_code")}: ` +
                stat.code +
                ")"
            }}:
          </p>
          <p class="robo-16-500 txt-pri mr-1">{{ stat.value || "---" }}</p>
          <p class="robo-16-400 txt-pri">
            {{ stat.unit }}
            <!-- {{ $t(`obj_health_records.lbl_unit_${stat.unit}`) }} -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import mdtUtils from '../../utils/mdtUtils.js'
export default {
  name: 'PersonProfile',
  props: ['person', 'disease', 'stats', 'type', 'monitor_stats'],
  data () {
    return {
      route_name: null
    }
  },
  computed: {
    isAdmin () {
      return appUtils.isAdmin()
    }
  },
  mounted () {
    this.route_name = this.$route.name
  },
  methods: {
    filterStats () {
      let self = this
      if (!self.isAdmin) {
        let stats = []
        self.monitor_stats.forEach((e) => {
          let stat_value =
            self.person?.stats?.find((s) => s.code === e.code) || null
          stats.push({
            ...e,
            name: e.name,
            value: stat_value ? stat_value.value : '--',
            unit: e.unit
          })
        })
        return stats
      } else {
        return self.person?.stats
      }
    },

    getGender (data) {
      return mdtUtils.getGender(data)
    },
    getAges (data) {
      return mdtUtils.getAges(new Date(data.birthday))
    },
    voiceCall (user) {
      this.$router.push({ path: `/call/${user.id}?pdid=${this.disease.id}` })
    },
    videoCall (user) {
      this.$router.push({
        path: `/call/${user.id}?videocall=1&pdid=${this.disease.id}`
      })
    }
  }
}
</script>
<style lang="css" scoped>
.avatar {
  width: 38px;
  height: 38px;
}

button {
  width: 140px;
}
</style>
