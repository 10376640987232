<template>
  <div class="container py-4">
    <div class="row">
      <div class="col-12 d-xl-flex justify-content-between">
        <div class="d-flex py-1 align-items-center">
          <img src="../../../public/assets/images/icon/icon-cancer.svg" alt class="mr-2" />
          <span class="txt-pri page-title">
            {{
            $t("obj_health_records.lbl_health_record")
            }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <DiseaseListBar
        :disease_list_type="
          type === 'pending' ? 'waiting-list' : 'profile-list'
        "
        @onShowModalCarePlan="onShowModalCarePlan"
        @addPatient="handleOpenModalAddPatient"
      ></DiseaseListBar>
      <!-- <button v-if="can('create-new-patient')" class="btn radius-0 bg-pri bd-pri text-white border-0 pl-5 pr-5 d-none" type="button" @click="onShowModalAddPerson(true)"><span class="fa fa-plus mr-2"></span> Thêm từ HIS</button> -->
    </div>
    <div class="card radius-10 pt-2 border-0">
      <div class="d-md-flex justify-content-between align-items-center px-2">
        <p class="robo-16-500 txt-pri mb-0">
          {{
          $t(`obj_health_records.fm_you_have_health_records`, {
          total: total_diseases,
          })
          }}
        </p>
        <div class="row">
          <div class="col-auto" v-if="type !== 'pending'">
            <div class="input-group input-search-box mb-1 bg-bold-item">
              <select
                class="form-select txt-pri robo-14-400"
                aria-label="Default select example"
                v-model="filter"
                @change="filterDiseases()"
              >
                <option
                  value
                  disabled
                  class="robo-14-400 font-weight-bold my-1"
                >{{ $t("obj_health_records.lbl_update_lastest") }}</option>
                <option
                  class="robo-14-400"
                  :class="
                    filter == 1 ? 'txt-pri font-weight-bold bg-f2' : 'txt-black'
                  "
                  :value="1"
                >{{ $t("obj_health_records.lbl_newest") }}</option>
                <option
                  class="robo-14-400"
                  :class="
                    filter == 2 ? 'txt-pri font-weight-bold bg-f2' : 'txt-black'
                  "
                  :value="2"
                >{{ $t("obj_health_records.fm_day_ago", { day: 10 }) }}</option>
                <option
                  class="robo-14-400"
                  :class="
                    filter == 3 ? 'txt-pri font-weight-bold bg-f2' : 'txt-black'
                  "
                  :value="3"
                >{{ $t("obj_health_records.fm_day_ago", { day: 20 }) }}</option>
                <option
                  class="robo-14-400"
                  :class="
                    filter == 4 ? 'txt-pri font-weight-bold bg-f2' : 'txt-black'
                  "
                  :value="4"
                >{{ $t("obj_health_records.fm_day_ago", { day: 30 }) }}</option>
                <option
                  class="robo-14-400"
                  :class="
                    filter == 5 ? 'txt-pri font-weight-bold bg-f2' : 'txt-black'
                  "
                  :value="5"
                >{{ $t("obj_health_records.lbl_oldest") }}</option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="input-group input-search-box mb-1">
              <input
                type="search"
                v-model="search"
                class="form-control"
                :placeholder="$t('obj_health_records.lbl_enter_name_search')"
                @keyup.enter="searchDiseases()"
                @keyup.delete="searchPatientClear()"
              />
              <span
                v-if="search"
                class="input-group-text border-0 radius-5 cursor-pointer"
                :class="!search ? 'disabled' : ''"
                @click="(search = ''), searchDiseases()"
              >
                <i class="fa fa-times-circle"></i>
              </span>
              <span
                class="input-group-text border-0 radius-5 font-size-20 cursor-pointer"
                @click="searchDiseases()"
              >
                <i class="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table table-scroll">
          <thead class="d-none d-md-table">
            <tr>
              <th class="txt--muted" style="width: 60px">
                <div class="d-flex py-1 align-items-center justify-center ml-2">
                  <b-form-checkbox v-model="selectAllPatient"></b-form-checkbox>
                </div>
              </th>
              <th class="txt--muted">{{ $t("obj_health_records.lbl_patient_info") }}</th>
              <th
                class="txt--muted"
                v-if="type !== 'pending'"
              >{{ $t("obj_health_records.lbl_phone") }}</th>
              <th
                class="txt--muted"
                v-if="type !== 'pending'"
              >{{ $t("obj_health_records.lbl_address") }}</th>
              <th class="txt--muted">{{ $t("obj_health_records.lbl_record") }}</th>
              <th
                class="txt--muted"
                v-if="type !== 'pending'"
              >{{ $t("obj_health_records.lbl_update_lastest") }}</th>
              <th
                class="txt--muted"
                v-if="type === 'pending'"
              >{{ $t("obj_health_records.lbl_status") }}</th>
              <th class="txt--muted" style="width: 80px"></th>
            </tr>
          </thead>
          <tbody
            id="diseases"
            v-if="!loading && diseases && diseases.count"
            :class="diseases && diseases.count == 10 ? 'h-100' : ''"
          >
            <tr
              class="mt-2 row-contact"
              v-for="disease in diseases.data"
              :class="disease.doctor_read ? 'bg-fa' : 'bg-bold-item'"
              :key="'d' + disease.id"
            >
              <td style="width: 60px">
                <div class="d-flex py-1 align-items-center justify-center ml-2">
                  <b-form-checkbox
                    v-model="selectedPatient"
                    size="lg"
                    class="pl-0"
                    :value="disease"
                  ></b-form-checkbox>
                </div>
              </td>
              <td data-label="Thông tin bệnh nhân" class="table-td-name-avatar">
                <div class="d-flex py-1 align-items-center ml-2">
                  <div style="min-width: 40px">
                    <span v-if="disease.person && disease.person.user">
                      <span
                        class="avatar avatar-sm avatar-rounded"
                        v-if="disease.person.user.avatar"
                        :style="
                          'background-image: url(' +
                          getImageURL(disease.person.user.avatar) +
                          ')'
                        "
                      ></span>
                      <span
                        v-if="!disease.person.user.avatar"
                        class="avatar avatar-sm avatar-rounded bg-secondary"
                      >
                        <span style="color: #fff">
                          {{
                          disease.person && disease.person.name
                          ? disease.person.name.charAt(0)
                          : ""
                          }}
                        </span>
                      </span>
                    </span>
                    <span
                      v-if="disease.person && !disease.person.user"
                      class="avatar avatar-sm avatar-rounded bg-secondary"
                    >
                      <span style="color: #fff">
                        {{
                        disease.person && disease.person.name
                        ? disease.person.name.charAt(0)
                        : ""
                        }}
                      </span>
                    </span>
                  </div>
                  <div class="flex-fill">
                    <p class="p-0 m-0">{{ disease.person ? disease.person.name : "" }}</p>
                  </div>
                </div>
              </td>
              <td data-label="Số điện thoại" v-if="type !== 'pending'">
                <p>{{ disease.person ? disease.person.phone : "" }}</p>
              </td>
              <td data-label="Địa chỉ" v-if="type !== 'pending'">
                <p class="mb-0">{{ disease.person ? disease.person.address : "" }}</p>
              </td>
              <td
                data-label="Hồ sơ"
                style="text-transform: capitalize"
              >{{ disease ? disease.diseases_name : "" }}</td>
              <td data-label="Cập nhật cuối" v-if="type !== 'pending'">
                {{
                disease && disease.updated_at
                ? formatHMDMY(disease.updated_at)
                : ""
                }}
              </td>
              <td data-label="Trạng thái" v-if="type === 'pending'">
                {{
                disease.status
                ? getStatus(disease.status)
                : $t("obj_health_records.lbl_waiting")
                }}
              </td>
              <td style="width: 80px">
                <svg
                  @click="goToDetail(
                  disease && disease.id,
                  disease && disease.doctor_read
                )"
                  class="cursor-pointer"
                  width="30"
                  height="26"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="#20409B" />
                  <mask
                    id="mask0_35008_774"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="6"
                    y="6"
                    width="18"
                    height="18"
                  >
                    <rect
                      x="6.92859"
                      y="6.92871"
                      width="16.1429"
                      height="16.1429"
                      fill="#D9D9D9"
                      stroke="#20409B"
                    />
                  </mask>
                  <g mask="url(#mask0_35008_774)">
                    <path
                      d="M15 17.8701C15.8962 17.8701 16.6583 17.5563 17.2857 16.9289C17.913 16.3015 18.2268 15.5394 18.2268 14.6433C18.2268 13.7471 17.913 12.985 17.2857 12.3577C16.6583 11.7303 15.8962 11.4165 15 11.4165C14.1039 11.4165 13.3418 11.7303 12.7144 12.3577C12.087 12.985 11.7732 13.7471 11.7732 14.6433C11.7732 15.5394 12.087 16.3015 12.7144 16.9289C13.3418 17.5563 14.1039 17.8701 15 17.8701ZM15 16.5593C14.4676 16.5593 14.0154 16.3731 13.6428 16.0005C13.2702 15.6279 13.084 15.1757 13.084 14.6433C13.084 14.1108 13.2702 13.6587 13.6428 13.286C14.0154 12.9134 14.4676 12.7272 15 12.7272C15.5325 12.7272 15.9846 12.9134 16.3573 13.286C16.7299 13.6587 16.9161 14.1108 16.9161 14.6433C16.9161 15.1757 16.7299 15.6279 16.3573 16.0005C15.9846 16.3731 15.5325 16.5593 15 16.5593ZM15 19.9879C13.2644 19.9879 11.6835 19.5035 10.257 18.5347C8.83193 17.5668 7.7984 16.2698 7.15632 14.6433C7.7984 13.0168 8.83193 11.7197 10.257 10.7518C11.6835 9.78301 13.2644 9.29863 15 9.29863C16.7356 9.29863 18.3165 9.78301 19.743 10.7518C21.1681 11.7197 22.2016 13.0168 22.8437 14.6433C22.2016 16.2698 21.1681 17.5668 19.743 18.5347C18.3165 19.5035 16.7356 19.9879 15 19.9879Z"
                      fill="#20409B"
                      stroke="#20409B"
                      stroke-width="0.025"
                    />
                  </g>
                </svg>
              </td>
            </tr>
          </tbody>
          <tbody id="diseases" v-if="(!diseases || !diseases.count) && !loading">
            <tr>
              <td colspan="4">
                <div class="col-sm-6 offset-sm-3 pt-3 pb-5 text-center">
                  <img
                    src="../../../public/assets/images/not-found-search.png"
                    class="img-responsive p-0"
                    alt
                  />
                  <p class="robo-16-400 text-black">
                    {{
                    $t(
                    search
                    ? "obj_health_records.lbl_search_not_found"
                    : "obj_health_records.lbl_record_not_found"
                    )
                    }}
                  </p>
                  <button
                    v-if="can('create-new-patient') && $user.is_verified === 4"
                    class="btn-xl btn--blue"
                    type="button"
                    @click="onShowModalCarePlan(true)"
                  >
                    <span class="fa fa-plus mr-2"></span>
                    {{ $t("obj_health_records.lbl_add_new") }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody id="diseases" v-if="loading">
            <tr v-for="i in 10" :key="'loading' + i">
              <td style="width: 60px">
                <div class="skeleton-checkbox"></div>
              </td>
              <td>
                <div class="skeleton-avatar avatar-sm avatar-rounded"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
          </tbody>
        </table>
        <!-- <Pagination
          v-if="diseases && diseases.count && !loading"
          :items="diseases"
          :current_page="pagination.currentPage"
          @onRefresh="onRefresh"
        ></Pagination>-->
        <AppPagination
          :paginationProps="pagination"
          @onChangePagination="handleChangePagination"
          class="px-3"
        />
      </div>
      <br />
    </div>
    <ModalPatientInfo @refresh="handleCompleteAddPerson" ref="ModalPatientInfo"></ModalPatientInfo>
    <ModalCarePlan
      :loading="loading_persons"
      :slt_person_id="slt_person_id"
      :new_person="new_person"
      v-if="persons"
      :persons="persons"
      @onRefresh="onRefreshCarePlane"
      @onShowModalAddPersons="onShowModalAddPersons"
      @onSubmit="onSubmitCarePlane"
      @searhPerson="searhPerson"
      @searchPersonDelete="searchPersonDelete"
    ></ModalCarePlan>
    <ModalAddPatient
      :listPatient="selectedPatient"
      :isShow="isShowModal"
      @addPatient="handleAddPatient"
    ></ModalAddPatient>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import ModalCarePlan from '../../components/Person/ModalCarePlan'
import Pagination from '../../components/Pagination'
import DiseaseListBar from '../../components/Diseases/DiseaseListBar'
import ModalPatientInfo from '../Patient/ModalPatientInfo.vue'
import ModalAddPatient from './ModalAddPatient.vue'
import { AppPagination } from '@/components/Common'

export default {
  components: {
    Pagination,
    ModalCarePlan,
    DiseaseListBar,
    ModalPatientInfo,
    ModalAddPatient,
    AppPagination
  },
  name: 'ListDiseases',
  computed: {
    className () {
      return appUtils.isClinic()
        ? 'ClinicRequest'
        : appUtils.isDoctor()
          ? 'DoctorRequest'
          : ''
    }
  },
  props: ['type'],
  data () {
    return {
      diseases: [],
      total_diseases: 0,
      search: '',
      params: {
        limit: '',
        sort: '',
        sort_by: '',
        search: '',
        page: 1
      },
      sort: 0,
      loading: false,
      loading_persons: false,
      user: null,
      clinic: null,
      list_total_request: null,
      load_end: false,
      current_page: 1,
      persons: null,
      slt_person_id: null,
      new_person: null,
      search_person: '',
      filter: 1,
      selectedPatient: [],
      selectAllPatient: false,
      isConflictClick: false,
      isShowModal: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },
  watch: {
    list_total_request: {
      handler: function () {},
      deep: true
    },
    diseases: {
      handler: function () {},
      deep: true
    },
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonDiseases()
      },
      deep: true,
      immediate: true
    },
    selectAllPatient (value) {
      if (value === true) {
        this.selectedPatient = this.diseases.data
      } else {
        this.selectedPatient = []
      }
    }
  },
  mounted () {
    let self = this
    self.user = appUtils.getLocalUser()
    self.clinic = this.$globalClinic
    self.getPersonDiseases()
    self.getPersons()
    window.$(document).ready(function () {
      window.$('#modal__add_person').on('hidden.bs.modal', function (e) {
        self.getPersons()
        self.err_name = ''
        self.err_phone = ''
        self.err_gender = ''
        self.err_year = ''
        self.err_address = ''
        self.birth = {}
      })
    })
  },
  methods: {
    onRefresh () {
      this.getPersonDiseases()
    },
    async getPersonDiseases () {
      let self = this
      self.loading = true
      // let clinic_id = this.$clinicId
      try {
        this.pagination.currentPage = parseInt(self.$route.query.page) || 1
        self.loading = true
        self.params = {
          limit: this.pagination.pageSize || 10,
          sort: 'updated_at',
          sort_by: 'desc',
          page: this.pagination.currentPage || 1
        }
        if (self.type === 'pending') {
          self.params.is_accepted = 0
        } else {
          self.params.is_accepted = 1
          // self.params.clinic_id = clinic_id
        }
        if (self.search) {
          self.params.keyword = self.search
        }
        if (self.filter) {
          let filter = self.filter
          if (filter === 1) {
            self.params.sort = 'updated_at'
            self.params.sort_by = 'desc'
          }
          if (filter === 2) {
            self.params.sort = 'updated_at'
            self.params.sort_by = 'asc'
            self.params.before_now = 10
          }
          if (filter === 3) {
            self.params.sort = 'updated_at'
            self.params.sort_by = 'asc'
            self.params.before_now = 20
          }
          if (filter === 4) {
            self.params.sort = 'updated_at'
            self.params.sort_by = 'asc'
            self.params.before_now = 30
          }
          if (filter === 5) {
            self.params.sort = 'updated_at'
            self.params.sort_by = 'asc'
          }
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonDiseases(self.params)
          .then(res => {
            self.diseases = res.data
            self.total_diseases = res.data.total
            this.pagination.totalItems = res.data.total
          })
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    // async onAddNewPerson (data) {
    //   let self = this
    //   if (!self.can('create-new-patient')) return
    //   let user = appUtils.getLocalUser()
    //   let clinic = this.$globalClinic
    //   try {
    //     self.loading_persons = true
    //     data.is_diseases = 1
    //     let res = await self.$rf.getRequest(self.className).postPerson(data)
    //     if (res && res.data && res.data.id) {
    //       await self.getPersons(null, res.data.id).then(() => {
    //         self.onShowModalAddPerson(false)
    //         self.onShowModalCarePlan(true)
    //       })
    //     }
    //     self.sendAmplitude('Create Cancer Patient', {
    //       'doctor id':
    //         user && user.doctor && user.doctor.id ? user.doctor.id : '',
    //       'doctor name': user && user.name ? user.name : '',
    //       'workspace id': clinic && clinic.id ? clinic.id : '',
    //       'workspace name': clinic && clinic.name ? clinic.name : '',
    //       timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY')
    //     })
    //   } catch (e) {
    //     self.sendAmplitude('Error Create Cancer Patient', {
    //       'doctor id':
    //         user && user.doctor && user.doctor.id ? user.doctor.id : '',
    //       'doctor name': user && user.name ? user.name : '',
    //       'workspace id': clinic && clinic.id ? clinic.id : '',
    //       'workspace name': clinic && clinic.name ? clinic.name : '',
    //       timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
    //       message: e
    //     })
    //     console.log(e)
    //   } finally {
    //     self.add_person.name = ''
    //     self.add_person.phone = ''
    //     self.add_person.gender = ''
    //     self.add_person.cmnd = ''
    //     self.add_person.address = ''
    //     self.add_person.symptom = ''
    //     self.loading_persons = false
    //   }
    // },
    formatPhoneNumber (number) {
      let cleaned = ('' + number).replace(/\D/g, '')
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        let hiden_number = ''
        for (var i = 0; i < match[2].length; i++) {
          hiden_number += 'X'
        }
        return match[1] + '-' + hiden_number + '-' + match[3]
      }
      return null
    },
    searchDiseases () {
      let self = this
      self.params.search = self.search
      self.$router.replace({ query: { page: 1 } }).catch(_ => {})
      self.getPersonDiseases()
      setTimeout(() => {
        self.sendAmplitude('Search Cancer Patients', {
          'doctor id':
            self.user && self.user.doctor && self.user.doctor.id
              ? self.user.doctor.id
              : '',
          'doctor name': self.user && self.user.name ? self.user.name : '',
          'workspace id': self.clinic && self.clinic.id ? self.clinic.id : '',
          'workspace name':
            self.clinic && self.clinic.name ? self.clinic.name : '',
          timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY')
        })
      }, 100)
    },
    filterDiseases () {
      setTimeout(() => {
        this.$router.push({ query: { page: 1 } }).catch(_ => {})
        this.getPersonDiseases()
      }, 100)
    },
    searchPatientClear () {
      let self = this
      if (self.search.length === 0) {
        self.$router.replace({ query: { page: 1 } }).catch(_ => {})
        self.getPersonDiseases()
      }
    },
    goToDetail (id, read) {
      // console.log(contact)
      if (this.isConflictClick === true) return
      if (this.isConflictClick === false) {
        if (!id) return
        let self = this
        if (self.type === 'pending') {
          self.$router
            .push({ path: `/doctor/diseases/${id}/waiting-profile-details` })
            .catch(_ => {})
        } else {
          if (!read) {
            self.readPersonDiseases(id)
          }
          self.$router.push({ path: `/doctor/diseases/${id}` }).catch(_ => {})
        }
      }
    },
    async readPersonDiseases (id) {
      this.$rf.getRequest('DoctorRequest').readPersonDiseases(id)
    },
    getImageURL (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
    },
    getDiseases (person) {
      let diseases = person.person_diseases
      let disease = null
      let string = ''
      if (diseases && diseases.length) {
        disease = diseases[diseases.length - 1]
      }
      if (disease) {
        let name = disease.diseases ? disease.diseases.name : ''
        let stage =
          `${this.$t('obj_health_records.lbl_disease_progression')} ` +
          disease.diseases_stage
        string = name + (name ? ', ' + stage : stage)
      }
      return string
    },
    getTags (disease) {
      let string = ''
      if (disease && disease.person_disease_tag) {
        disease.person_disease_tag.forEach(function (element, index) {
          if (element.type === 1) {
            let d =
              element && element.tag && element.tag.name
                ? element.tag.name + ' - '
                : ''
            string += d
          }
        })
      }
      return string.substring(0, string.length - 2)
    },
    isClinic () {
      var user = this.$globalClinic
      if (user && user.doctor_id === user.pivot.doctor_id) {
        return true
      }
      return false
    },
    onShowModalCarePlan (show) {
      window.$('#modal--care-plan').modal(show ? 'show' : 'hide')
    },
    onShowModalAddPerson (show) {
      if (!this.can('create-new-patient')) return
      show
        ? this.$refs.ModalPatientInfo.openDialog()
        : this.$refs.ModalPatientInfo.handleClose()
    },
    onShowModalAddPersons (show) {
      let self = this
      self.onShowModalAddPerson(show)
    },
    onSubmitCarePlane (person, temp) {
      let self = this
      if (!person && !person.id) {
        self.onShowModalCarePlan(false)
      } else {
        self.onShowModalCarePlan(false)
        if (!temp) {
          self.$router
            .push({ path: `/doctor/diseases/${person.id}/create` })
            .catch(() => {})
        } else {
          self.$router
            .push({
              path: `/doctor/diseases/${person.id}/template/${temp.id}/create`
            })
            .catch(() => {})
        }
      }
    },
    async getPersons (num, slt_person_id) {
      let self = this
      let clinic_id = this.$clinicId
      if (!clinic_id) return
      try {
        this.pagination.currentPage = parseInt(self.$route.query.page) || 1
        self.loading_persons = !num
        self.params = {
          limit: num || 50,
          sort: 'created_at',
          sort_by: 'desc',
          search: self.search_person
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsOfWorkspace(clinic_id, self.params)
          .then(res => {
            self.persons = res.data
            if (slt_person_id) self.slt_person_id = slt_person_id
          })
        return true
      } catch (e) {
        console.log(e)
      } finally {
        self.loading_persons = false
      }
    },
    getStatus (status) {
      let str = ''
      if (status === 1) {
        str = this.$t('obj_health_records.lbl_waiting')
      } else if (status === 2) {
        str = this.$t('obj_health_records.lbl_processing')
      } else if (status === 3) {
        str = this.$t('obj_health_records.lbl_close')
      } else {
        str = this.$t('obj_health_records.lbl_waiting')
      }
      return str
    },
    onRefreshCarePlane (limit) {
      let self = this
      self.getPersons(limit)
    },
    searhPerson (search) {
      let self = this
      self.search_person = search
      self.getPersons()
    },
    searchPersonDelete () {
      let self = this
      self.search_person = ''
      self.getPersons()
    },
    formatHMDMY (date_time) {
      const date = new Date(date_time)
      return window.moment(date).format('HH:mm DD/MM/YY')
    },
    handleCompleteAddPerson (data) {
      this.new_person = data
    },
    handleOpenModalAddPatient () {
      if (this.selectedPatient.length === 0) {
        this.$message.error('Bạn cần chọn bệnh nhân')
      } else this.showModalAddPatient(true)
    },
    showModalAddPatient (is_show) {
      window.$('#model-add-patient').modal(is_show ? 'show' : 'hide')
      this.isShowModal = is_show
    },
    handleAddPatient () {
      this.selectedPatient = []
      this.selectAllPatient = false
      this.showModalAddPatient(false)
      this.isShowModal = false
      this.onRefresh()
    },
    handleChangePagination (value) {
      this.pagination = {
        ...this.pagination,
        ...value
      }
      this.$router.push({ query: { page: value.currentPage } }).catch(_ => {})
      this.getPersonDiseases()
    }
  }
}
</script>
<style scoped>
.sort-both {
  background-image: url('../../../public/assets/images/icon/sort_both.png');
  background-repeat: no-repeat;
  background-position: center;
}

.sort-down {
  background-image: url('../../../public/assets/images/icon/sort_down.png');
  background-repeat: no-repeat;
  background-position: center;
}

.sort-up {
  background-image: url('../../../public/assets/images/icon/sort_up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.center {
  display: flex;
  align-items: center;
}

.row-contact {
  background-color: #f2f5fa !important;
  border-radius: 10px;
}

.row-contact:hover {
  background-color: #ebf1ff !important;
  cursor: pointer;
}

.next {
  color: #b6c2cf;
}

.row-contact:hover .next {
  color: black;
}

.bg-fa {
  background-color: #fafafa !important;
}

.input-search-box span,
.input-search-box input {
  background-color: inherit !important;
}

.input-search-box {
  border: 1.0625px solid #c3cae7;
  max-width: 100%;
  width: 270px;
}

.bg-bold-item {
  background-color: #e7eaf5 !important;
}
.form-control {
  height: 40px;
}
.form-select {
  background-image: url(../../../public/assets/images/icon/sort-icon.svg);
  background-size: 24px;
  background-position: right 0.25rem center;
  width: 250px;
}
.bg-f2 {
  background-color: #f2f2f2;
}
</style>