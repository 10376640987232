<template>
  <div>
    <div class="modal" tabindex="-1" role="dialog" id="modal__document">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-pri">
            <h5 class="modal-title">
              {{ $t("obj_health_records.lbl_attachment") }}
            </h5>
            <span class="btn--close"></span>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div style="padding: 15px 15px">
                  <div class="row">
                    <div class="col-sm-12 mb-3">
                      <div class="form-group">
                        <label class="robo-16-500 txt-pri required">
                          {{ $t("obj_health_records.lbl_test") }}
                        </label>
                        <el-select
                          reserve-keyword
                          :placeholder="$t('obj_health_records.lbl_test_name')"
                          class="w-100 mt-2 form-control txt-pri robo-16-500"
                          :loading="is_loading"
                          v-model="template_id"
                          @change="commitName"
                        >
                          <el-option
                            v-for="item in templates"
                            :key="item.id + 'dtr'"
                            :label="item.name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="col-sm-12 mb-3">
                      <div class="form-group">
                        <label class="robo-16-500 txt-pri required">
                          {{ $t("obj_health_records.lbl_content") }}
                        </label>
                        <input
                          type="text"
                          class="form-control mt-2 txt-pri robo-16-500"
                          :placeholder="
                            $t('obj_health_records.lbl_enter_content')
                          "
                          v-model="indication.content"
                          @change="changeContent"
                        />
                      </div>
                    </div>
                    <div
                      class="col-sm-12 d-flex justify-content-between align-items-center flex-wrap"
                    >
                      <p class="mb-0">
                        {{
                          file
                            ? file.name
                            : `${$t("obj_health_records.lbl_no_select_file")}`
                        }}
                      </p>
                      <p
                        v-if="file"
                        style="border-bottom: solid 1.5px #20419b"
                        @click="onShowPickFile()"
                        class="cursor-pointer txt-pri d-flex mb-0"
                      >
                        {{ $t("obj_health_records.lbl_modify") }}
                      </p>
                      <button
                        v-if="!file"
                        class="btn radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-16-500 mr-2 text-right"
                        @click="onShowPickFile()"
                      >
                        <img
                          src="../../../public/assets/images/icon/upload.svg"
                          class="mr-2"
                          style="height: 24px; width: 24px"
                        />
                        {{ $t("obj_health_records.lbl_select_file") }}
                      </button>
                    </div>
                    <div class="col-12">
                      <small class="text-red">{{ err_file }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-muted" data-dismiss="modal">
              {{ $t("obj_health_records.lbl_cancel") }}
            </button>
            <button
              :disabled="is_loading"
              type="button"
              class="btn bg-pri bd-pri text-white"
              @click="onSubmit()"
            >
              {{ $t("obj_health_records.lbl_upload") }}

              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="is_loading"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalDocument',
  props: ['file', 'loading'],
  data () {
    return {
      content: '',
      err_file: '',
      exts_list: [
        'jpeg',
        'png',
        'jpg',
        'gif',
        'svg',
        'doc',
        'docx',
        'pdf',
        'xls',
        'xlsx',
        'xlsm',
        'ppt',
        'pptx',
        'zip',
        'rar'
      ],
      is_loading: false,
      template_id: null,
      indication: {
        name: '',
        content: ''
      },
      templates: []
    }
  },
  watch: {
    loading: function (_new, old) {
      this.is_loading = _new
    }
  },
  mounted () {
    this.getTestTemplates()
  },
  methods: {
    commitName () {
      const found = this.templates.find((x) => x.id === this.template_id)
      if (found) {
        this.indication.name = found.name
      }
    },
    async getTestTemplates () {
      let self = this
      let params = {
        limit: 200,
        is_active: 1
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getTestTemplates(params)
        .then((res) => {
          this.templates = res.data.data
        })
    },
    onSubmit () {
      let self = this
      if (self.is_loading) return
      if (!self.file) {
        self.err_file = this.$t('obj_health_records.lbl_please_select_file')
        return false
      } else if (!self.validateFile(self.file)) {
        self.err_file = this.$t(
          'obj_health_records.lbl_please_select_file_valid'
        )

        return false
      } else {
        self.$emit('onSubmit', this.indication)
      }
    },
    validateFile (file) {
      let ext = file.name.split('.').pop()
      return !!this.exts_list.find((el) => el === ext.toLowerCase())
    },
    onSelectFile (event) {
      this.file = event.target.files[0]
      let r = confirm(
        `${this.$t('obj_health_records.lbl_confirm_upload_file')} ` +
          this.file.name
      )
      if (r) {
      } else {
        alert(this.$t('obj_health_records.lbl_canceled_upload'))
        this.file = null
      }
    },
    onShowPickFile () {
      this.err_file = ''
      this.$emit('onShowPickFile')
    },
    changeContent () {
      this.$emit('changeContent', this.indication)
    }
  }
}
</script>
<style scoped="true">
</style>