<template>
  <div class="bd-e4">
    <div class="row mb-4">
      <div class="col-12 mb-3">
        <div class="flex flex-col md:flex-row justify-between">
          <div class>
            <label for class="mb-2">
              <span class="robo-20-500 txt-pri">
                {{
                $t("obj_health_records.lbl_metrics_to_track")
                }}
              </span>
              <span
                class="robo-16-400 txt-pri ml-3"
                v-if="
                  disease &&
                  disease.person &&
                  disease.person.stats &&
                  disease.person.stats.length
                "
              >
                {{ $t("obj_health_records.lbl_update_lastest") }}
                {{ formatStatsDateDMY(disease.person.stats) }}
              </span>
            </label>
          </div>
          <div class v-if="type !== 'waiting-profile-details' && isDoctor()">
            <button
              class="btn radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-18-500 gap-2"
              style="height: 34px; width: 100px"
              @click="openSetting"
            >
              <img
                src="../../../public/assets/images/icon/icon-setting.svg"
                style="height: 24px; width: 24px"
              />
              {{ $t("obj_health_records.lbl_setting") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="row my-2"
        v-if="disease && disease.person && disease.person.stats"
        :set="(filtered_stats = filterStats())"
      >
        <div
          class="col-xxl-3 col-md-4 col-xs-6 d-flex align-items-center"
          v-for="stat in filtered_stats"
          :key="stat.id"
        >
          <p
            class="robo-16-500 txt-grey-600 mr-1"
          >{{ stat.name ? stat.name : "(Mã chỉ số: " + stat.code + ")" }}:</p>
          <p class="robo-16-500 txt-pri mr-1">{{ stat.value || "---" }}</p>
          <p class="robo-16-400 txt-pri">{{ stat.unit }}</p>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 mb-3">
        <div class="flex flex-col md:flex-row justify-between">
          <div class>
            <label for class="mb-2">
              <span class="robo-20-500 txt-pri">{{ $t("obj_health_records.lbl_abnormal_signs") }}</span>
            </label>
          </div>
          <div class v-if="type !== 'waiting-profile-details' && isDoctor()">
            <button
              class="btn radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-18-500 gap-2"
              style="height: 34px; width: 100px"
              @click="openSetting"
            >
              <img
                src="../../../public/assets/images/icon/icon-setting.svg"
                style="height: 24px; width: 24px"
              />
              <span>{{ $t("obj_health_records.lbl_setting") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div
            v-if="symptoms_values && symptoms_values.length > 0"
            class="d-none d-lg-block mb-3"
            style="padding-left: 2.5rem"
          >
            <div class="row grid grid-cols-5">
              <div
                class="col-lg-2 robo-16-500 w-100 text-gray-dark"
              >{{ $t("obj_health_records.lbl_time_v2") }}</div>
              <div class="col-lg-1 robo-16-500 w-100 text-gray-dark"></div>
              <div
                class="col-lg-3 robo-16-500 w-100 text-gray-dark"
              >{{ $t("obj_health_records.lbl_signal") }}</div>
              <div
                class="col-lg-3 robo-16-500 w-100 text-gray-dark"
              >{{ $t("obj_health_records.lbl_frequency") }}</div>
              <div
                class="col-lg-6 robo-16-500 w-100 text-gray-dark"
              >{{ $t("obj_health_records.lbl_patient_description") }}</div>
            </div>
          </div>
          <div v-if="symptoms_values && symptoms_values.length > 0">
            <div class="tracking-item border-bottom">
              <div class="tracking-icon"></div>
              <div
                class="row tracking-content mb-2 grid grid-cols-5"
                v-for="ele in symptoms_values"
                :key="ele.id"
              >
                <div class="col-lg-2 responsive-lg w-100" data-title="Thời gian">
                  <div
                    class="robo-20-500 txt-pri"
                  >{{ ele && ele.created_at && formatDateDMY(ele.created_at) }}</div>
                </div>
                <div class="col-lg-1 robo-20-400 txt-pri responsive-lg d-flex flex-wrap w-100">
                  {{
                  ele && ele.created_at ? formatDateHM(ele.created_at) : ""
                  }}
                </div>
                <div class="col-lg-3 robo-20-400 txt-pri responsive-lg w-100" data-title="Dấu hiệu">
                  <span class="ml-2">{{ ele && ele.symptom_name }}</span>
                </div>
                <div
                  class="col-lg-6 robo-20-400 txt-pri responsive-lg flex flex-col gap-2 w-100"
                  data-title="Tần suất"
                >
                  <div v-for="item in ele.person_symptom_value" :key="item.id">
                    <span class="ml-3">{{ item.frequency }}</span>
                  </div>
                </div>
                <div
                  class="col-lg-6 robo-20-400 txt-pri responsive-lg flex flex-col gap-2 w-100"
                  data-title="Mô tả của bệnh nhân"
                >
                  <div v-for="item in ele.person_symptom_value" :key="item.id">
                    <span class="ml-3" style="min-height: 26px">
                      {{
                      item.description
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="symptoms_total == 0" class="mb-3 mt-3">
            <div class="text-center">
              <span class="robo-20-500 txt-pri">{{ $t("obj_health_records.lbl_data_not_found") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import _ from 'lodash'
export default {
  name: 'ParameterMonitoring',
  props: ['disease', 'type', 'is_refresh'],
  watch: {
    is_refresh: function (_new, old) {
      if (_new) {
        this.getStats()
        this.getPersonSymptomsValue()
      }
    }
  },
  data () {
    return {
      person_diseases_id: '',
      monitor_stats: [],
      symptoms_values: null,
      symptoms_total: 0
    }
  },
  computed: {
    isAdmin () {
      return appUtils.isAdmin()
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.getStats()
    self.getPersonSymptomsValue()
  },
  methods: {
    filterStats () {
      let self = this
      if (!self.isAdmin) {
        let stats = []
        self.monitor_stats.forEach(e => {
          // let stat_value =
          //   self.disease?.person?.stats?.find(s => s.code === e.code) || null
          let stat_value = ''
          if (e.min_threshold || e.max_threshold) {
            stat_value = `${e.min_threshold} - ${e.max_threshold}`
          }
          stats.push({
            name: e.name,
            value: stat_value || '--',
            unit: e.unit
          })
        })
        return stats
      } else {
        return self.disease?.person?.stats
      }
    },
    openSetting () {
      this.$emit('openSetting', true)
    },
    isDoctor () {
      return appUtils.isDoctor()
    },
    getStatName (code) {
      return this.$t(
        code === 'weight'
          ? 'obj_health_records.lbl_weight'
          : code === 'height'
            ? 'obj_health_records.lbl_height'
            : code === 'tim_mach'
              ? 'obj_health_records.lbl_circuit'
              : code === 'huyet_ap'
                ? 'obj_health_records.lbl_blood_pressure'
                : code === 'nhip_tho'
                  ? 'obj_health_records.lbl_breathing'
                  : code === 'nhiet_do'
                    ? 'obj_health_records.lbl_body_temperature'
                    : code === 'spo2'
                      ? 'SPO2'
                      : code === 'glucose'
                        ? 'obj_health_records.lbl_blood_glucose'
                        : ''
      )
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest(
          appUtils.isDoctor()
            ? 'DoctorRequest'
            : appUtils.isAdmin()
              ? 'AdminRequest'
              : ''
        )
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then(resp => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    formatDateDMY (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    formatStatsDateDMY (stats) {
      if (!stats || !stats.length) return
      const array = _.sortBy(stats, item => {
        return new Date(item.updated_at)
      })
      return this.formatDateDMY(_.last(array).updated_at)
    },
    formatDateHM (date_time) {
      return window.moment(date_time).format('HH:mm')
    },
    async getPersonSymptomsValue () {
      let self = this
      try {
        await self.$rf
          .getRequest(
            appUtils.isDoctor()
              ? 'DoctorRequest'
              : appUtils.isAdmin()
                ? 'AdminRequest'
                : ''
          )
          .getPersonSymptoms(self.$route?.params?.id, {
            // is_doctor: 1
          })
          .then(res => {
            if (res && res.data) {
              self.symptoms_values = res.data?.data?.filter(
                elm => elm.is_monitor === 1
              )
              self.symptoms_total = res.data?.data?.filter(
                elm => elm.is_monitor === 1
              )?.length
            }
          })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
#tracking {
  margin-bottom: 1rem;
}
.tracking-item {
  border-left: 1px solid #20419b;
  position: relative;
  padding: 0rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  min-height: 5rem;
}
.tracking-item:last-child {
  padding-bottom: 4rem;
}
.tracking-item .tracking-content {
  padding: 0;
  background-color: transparent;
}
.tracking-item .tracking-content span {
  display: block;
  color: #888;
  font-size: 85%;
}
.tracking-item .tracking-icon {
  line-height: 2.6rem;
  position: absolute;
  left: -0.8rem;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: #20419b;
  color: #20419b;
}
@media (max-width: 992px) {
  .responsive-lg:before {
    content: attr(data-title);
    display: block;
    color: #1d2939;
    font-size: 20px;
    font-weight: 500px;
  }
}
</style>