<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" id="modal_test_create">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-pri">
            <h5 class="modal-title">
              {{
                slt_temp && slt_temp.name
                  ? slt_temp.name
                  : $t("obj_health_records.lbl_test")
              }}
            </h5>
            <span class="btn--close"></span>
          </div>
          <div class="modal-body py-3 px-4">
            <div class="item-wrapper">
              <div class="left-content" align="center"></div>
              <div class="right-content">
                <div class="row mb-4">
                  <label
                    for="test_name"
                    class="required robo-16-500 txt-grey-600 mb-1"
                    >{{ $t("obj_health_records.lbl_test_name") }}</label
                  >
                  <input
                    type="text"
                    class="form-control bg-blue-gray"
                    v-model="test_name"
                    :class="!test_name ? 'is-invalid' : ''"
                  />
                  <div v-if="!test_name" class="invalid-feedback">
                    {{ $t("obj_health_records.lbl_please_enter_test_name") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item-wrapper">
              <div class="left-content" align="center"></div>
              <div class="right-content">
                <div class="row mb-2">
                  <div class="col-4">
                    <label
                      for="test_name"
                      class="required robo-16-500 txt-grey-600 mb-1"
                    >
                      {{ $t("obj_health_records.lbl_quotient") }}
                    </label>
                  </div>
                  <div class="col-2">
                    <label
                      for="test_name"
                      class="required robo-16-500 txt-grey-600 mb-1"
                    >
                      {{ $t("obj_health_records.lbl_result") }}
                    </label>
                  </div>
                  <div class="col-2">
                    <label
                      for="test_name"
                      class="required robo-16-500 txt-grey-600 mb-1"
                    >
                      {{ $t("obj_health_records.lbl_unit") }}
                    </label>
                  </div>
                  <div class="col-2">
                    <label
                      for="test_name"
                      class="required robo-16-500 txt-grey-600 mb-1"
                    >
                      {{ $t("obj_health_records.lbl_below") }}
                    </label>
                  </div>
                  <div class="col-2">
                    <label
                      for="test_name"
                      class="required robo-16-500 txt-grey-600 mb-1"
                    >
                      {{ $t("obj_health_records.lbl_above") }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(item, idx) in test_items"
              class="item-wrapper mb-2"
              :set="(validate = validateData(item))"
            >
              <div
                class="left-content d-flex justify-content-center align-items-center"
                align="center"
              >
                <img
                  src="../../../public/assets/images/icon/trash.svg"
                  width="24"
                  height="24"
                  class="cursor-pointer"
                  alt=""
                  @click="onDelete(idx)"
                />
              </div>
              <div class="right-content">
                <div class="row">
                  <div class="col-4">
                    <input
                      type="text"
                      class="form-control bg-blue-gray"
                      :class="
                        !!validate.test_name && validate.test_name !== ''
                          ? 'is-invalid'
                          : ''
                      "
                      v-model="item.test_name"
                    />
                    <div v-if="validate.test_name" class="invalid-feedback">
                      {{ validate.test_name }}
                    </div>
                  </div>
                  <div class="col-2">
                    <input
                      type="number"
                      step="any"
                      class="form-control bg-blue-gray"
                      :class="
                        !!validate.test_value || validate.test_value !== ''
                          ? 'is-invalid'
                          : ''
                      "
                      v-model="item.test_value"
                    />
                    <div v-if="validate.test_value" class="invalid-feedback">
                      {{ validate.test_value }}
                    </div>
                  </div>
                  <div class="col-2">
                    <input
                      type="text"
                      class="form-control bg-blue-gray"
                      :class="
                        !!validate.test_unit && validate.test_unit !== ''
                          ? 'is-invalid'
                          : ''
                      "
                      v-model="item.test_unit"
                    />
                    <div v-if="validate.test_unit" class="invalid-feedback">
                      {{ validate.test_unit }}
                    </div>
                  </div>
                  <div class="col-2">
                    <input
                      type="number"
                      step="any"
                      class="form-control bg-blue-gray"
                      :class="
                        !!validate.lower_threshold &&
                        validate.lower_threshold !== ''
                          ? 'is-invalid'
                          : ''
                      "
                      v-model="item.lower_threshold"
                    />
                    <div
                      v-if="validate.lower_threshold"
                      class="invalid-feedback"
                    >
                      {{ validate.lower_threshold }}
                    </div>
                  </div>
                  <div class="col-2">
                    <input
                      type="number"
                      step="any"
                      class="form-control bg-blue-gray"
                      :class="
                        !!validate.upper_threshold &&
                        validate.upper_threshold !== ''
                          ? 'is-invalid'
                          : ''
                      "
                      v-model="item.upper_threshold"
                    />
                    <div
                      v-if="validate.upper_threshold"
                      class="invalid-feedback"
                    >
                      {{ validate.upper_threshold }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <a
                href="javascript:;"
                @click="addATest()"
                class="robo-20-500 txt-pri"
              >
                <img
                  src="../../../public/assets/images/icon/plus-circle.svg"
                  alt=""
                  class="mr-2"
                  width="24"
                  height="24"
                />
                {{ $t("obj_health_records.lbl_add_index") }}
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center my-3">
            <button
              :disabled="is_loading"
              type="button"
              class="btn bg-pri bd-pri text-white px-5 radius-0"
              @click="onSubmit()"
            >
              <span class="mx-3">
                {{ $t("obj_health_records.lbl_save") }}
              </span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                v-if="is_loading"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalCreateTests',
  props: ['slt_temp', 'tests', 'loading'],
  data () {
    return {
      is_loading: false,
      test_items: [],
      test_name: ''
    }
  },
  watch: {
    loading: function (_new, old) {
      this.is_loading = _new
    },
    tests: function (_new, old) {
      this.test_items = _new
    },
    slt_temp: function (_new, old) {
      this.test_name = _new.name
    }
  },
  mounted () {},
  methods: {
    onSubmit () {
      let self = this
      if (!self.test_items || !self.test_items.length) {
        alert(this.$t('obj_health_records.lbl_not_add_index'))
        return
      }
      if (!self.test_name || self.test_name === '' || self.test_name === null) {
        return
      }
      let is_invalid = self.test_items.filter(
        (t) => self.validateData(t).is_invalid
      )
      if (is_invalid.length) return
      self.$emit('onSaveTest', self.test_items, self.test_name)
    },
    onDelete (index) {
      this.test_items.splice(index, 1)
    },
    addATest () {
      let self = this
      if (!self.slt_temp) return
      self.test_items.push({
        test_name: '',
        test_value: '',
        test_unit: '',
        lower_threshold: '',
        upper_threshold: ''
      })
    },
    validateData (test) {
      let params = {
        is_invalid: false,
        test_name: '',
        test_value: '',
        test_unit: '',
        upper_threshold: '',
        lower_threshold: ''
      }
      if (!test.test_name) {
        params.is_invalid = true
        params.test_name = this.$t('obj_health_records.lbl_please_enter_name')
      }

      if (
        test.test_value === '' ||
        test.test_value === null ||
        isNaN(test.test_value) ||
        parseFloat(test.test_value) < 0
      ) {
        params.is_invalid = true
        params.test_value =
          test.test_value === '' || test.test_value === null
            ? this.$t('obj_health_records.lbl_please_enter_result')
            : isNaN(test.test_value)
              ? this.$t('obj_health_records.lbl_index_must_be_a_number')
              : parseFloat(test.test_value) < 0
                ? this.$t(
                  'obj_health_records.lbl_index_must_be_greater_than_or_equal_to_0'
                )
                : ''
      }

      if (!test.test_unit) {
        params.is_invalid = true
        params.test_unit = this.$t('obj_health_records.lbl_please_enter_unit')
      }

      if (
        test.upper_threshold === '' ||
        isNaN(test.upper_threshold) ||
        test.upper_threshold < 0 ||
        test.upper_threshold === null
      ) {
        params.is_invalid = true
        params.upper_threshold =
          test.upper_threshold === '' || test.upper_threshold === null
            ? this.$t('obj_health_records.lbl_please_enter_the_above_threshold')
            : isNaN(test.upper_threshold)
              ? this.$t('obj_health_records.lbl_above_must_be_a_number')
              : parseFloat(test.upper_threshold) < 0
                ? this.$t(
                  'obj_health_records.lbl_above_must_be_greater_than_or_equal_to_0'
                )
                : ''
      }

      if (
        test.lower_threshold === '' ||
        test.lower_threshold === null ||
        isNaN(test.lower_threshold) ||
        test.lower_threshold < 0 ||
        (!isNaN(test.lower_threshold) &&
          !isNaN(test.upper_threshold) &&
          parseFloat(test.upper_threshold) <= parseFloat(test.lower_threshold))
      ) {
        params.is_invalid = true
        params.lower_threshold =
          test.lower_threshold === '' || test.lower_threshold === null
            ? this.$t('obj_health_records.lbl_please_enter_the_below_threshold')
            : isNaN(test.lower_threshold)
              ? this.$t('obj_health_records.lbl_below_must_be_a_number')
              : test.lower_threshold < 0
                ? this.$t(
                  'obj_health_records.lbl_below_must_be_greater_than_or_equal_to_0'
                )
                : !isNaN(test.lower_threshold) &&
              !isNaN(test.upper_threshold) &&
              parseFloat(test.upper_threshold) <=
                parseFloat(test.lower_threshold)
                  ? this.$t('obj_health_records.lbl_below_small_than_above')
                  : ''
      }
      return params
    }
  }
}
</script>
<style lang="css" scoped="true">
.item-wrapper {
  width: 100%;
  display: flex;
}

.left-content {
  width: 52px;
  margin: 0 auto;
}

.right-content {
  width: calc(100% - 52px);
}

.form-control {
  font-size: 16px;
  font-weight: 400;
  font-family: "roboto";
  color: #222222;
  border-radius: 0;
}
</style>